import moment from "moment"
import { 
    CompetitionReducerProps, 
    V1_LOAD_COMPETITION_OPTIONS, 
    V1_LOAD_PLAYER_BRACKETS, 
    V1_LOAD_SQUARES_COMPETITIONS,
    V1_UPDATE_SQUARES_COMPETITION, 
    V1_UPDATE_BRACKET_COMPETITIONS, 
    V1_UPDATE_BRACKET_COMPETITION_SCORING_RULES, 
    V1_UPDATE_COMPETITIONS, 
    V1_UPDATE_COMPETITION_MATCHES, 
    V1_UPDATE_COMPETITION_MATCH_MARKETS, 
    V1_UPDATE_COMPETITION_PLAYERS, 
    V1_UPDATE_COMPETITION_PLAYER_BRACKETS, 
    V1_UPDATE_COMPETITION_RECORDS, 
    V1_UPDATE_COMPETITION_RESULTS, 
    V1_UPDATE_COMPETITION_SEASONS, 
    V1_UPDATE_DRAFT_COMPETITION, 
    V1_UPDATE_DRAFT_WAGER_ORDER, 
    V1_UPDATE_INVITED_COMPETITION_PLAYERS, 
    V1_UPDATE_MY_PLAYER_PICKS,
    V1_UPDATE_PLAYER_BRACKET_PICKS,
    V1_UPDATE_PLAYER_PICKS,
    V1_UPDATE_SEASON_LEADERS,
    V1_UPDATE_SQUARE_DETAILS
} from "../actionsv1/types"
import { CompetitionMatchMarketProps, CompetitionMatchProps, CompetitionPlayerProps, CompetitionProps, CompetitionRecordProps, CompetitionResultProps, PlayerPickProps } from "../types/tp_svc"

const DEFAULT_STATE:CompetitionReducerProps = {
    competition_result_types: [],
    competition_types: [],
    draft_competition: undefined,
    stored_competitions: {
        updated:moment().milliseconds(),
        competitions:[]
    },
    competition_seasons_list: {
        updated:1,
        competition_seasons:[]
    },
    season_leaders_list:{
        updated:1,
        season_leaders: [],
        competition_season_results: []
    },
    stored_records: {
        updated:moment().milliseconds(),
        records: []
    },
    stored_results: {
        updated:moment().unix(),
        results: []
    },
    bracket_competitions_list: {
        updated:0,
        bracket_competitions:[]
    },
    bracket_competition_scoring_rules_list: {
        updated:0,
        bracket_competition_scoring_rules: []
    },
    competition_players: [],
    competition_matches: [],
    competition_match_markets: [],
    my_picks: [],
    invited_competition_players: [],
    player_picks: [],
    squares_competitions_list: {
        updated: 0,
        squares_competitions: []
    },
    squares: [],
    player_squares:[],
    squares_type:undefined, 
    squares_payout_type: undefined, 
    squares_competition:undefined,
    square_results:[],
    player_brackets_list: {
        updated:0,
        player_brackets:[]
    },
    player_bracket_picks_list: {
        updated: 0,
        player_bracket_picks: []
    },
    competition_player_brackets_list: {
        updated: 0,
        competition_player_brackets: []
    },
    square_offers:[],
    wager_order:undefined,
    //offeree_offers:action.offeree_offers,
    player_square_history: [],
    squares_last_updated: moment()
}

const compReducer = (state = DEFAULT_STATE, action:any):CompetitionReducerProps => {
    switch(action.type){
        case V1_LOAD_COMPETITION_OPTIONS:
            return {
                ...state,
                competition_result_types: action.competition_result_types,
                competition_types: action.competition_types
            }
        case V1_UPDATE_DRAFT_COMPETITION:
            return {
                ...state,
                draft_competition: { ...action.draft_competition, updated:moment().milliseconds() }
            }
        case V1_UPDATE_COMPETITION_SEASONS:
            return {
                ...state,
                competition_seasons_list: {
                    updated: state.competition_seasons_list.updated + 1,
                    competition_seasons: state.competition_seasons_list.competition_seasons.filter(cs => !action.competition_seasons.find(ncs => ncs.competition_season_id == cs.competition_season_id)).concat(action.competition_seasons)
                }
            }
        case V1_UPDATE_SEASON_LEADERS:
            return {
                ...state,
                season_leaders_list: {
                    updated: state.season_leaders_list.updated + 1,
                    season_leaders: state.season_leaders_list.season_leaders.filter(sl => !action.season_leaders.find(nsl => `${nsl.competition_season_id}:${nsl.player_id}` == `${sl.competition_season_id}:${sl.player_id}`)).concat(action.season_leaders),
                    competition_season_results: state.season_leaders_list.competition_season_results.filter(sr => !action.competition_season_results.find(nsr => nsr.competition_season_result_id == sr.competition_season_result_id)).concat(action.competition_season_results)
                }
            }
        case V1_UPDATE_INVITED_COMPETITION_PLAYERS:
            return {
                ...state,
                invited_competition_players: state.invited_competition_players.filter(cp => !action.competition_players.find((ncp:CompetitionPlayerProps) => ncp.competition_player_id == cp.competition_player_id)).concat(action.competition_players)
            }
        case V1_UPDATE_DRAFT_WAGER_ORDER:
            return {
                ...state,
                wager_order:action.order
            }
        case V1_UPDATE_COMPETITIONS:
            return {
                ...state,
                stored_competitions: {
                    updated: Math.random(),
                    competitions: state.stored_competitions.competitions.filter(c => !action.competitions.find((nc:CompetitionProps) => nc.competition_id == c.competition_id)).concat(action.competitions)
                }
            }
        case V1_UPDATE_COMPETITION_PLAYERS:
            return {
                ...state,
                competition_players: action.offset === 0 ? action.competition_players : state.competition_players.filter(cp => !action.competition_players.find((ncp:CompetitionPlayerProps) => ncp.competition_player_id == cp.competition_player_id)).concat(action.competition_players)
            }
        case V1_UPDATE_COMPETITION_MATCHES:
            return {
                ...state,
                competition_matches: action.offset === 0 ? action.competition_matches : state.competition_matches.filter(cm => !action.competition_matches.find((ncm:CompetitionMatchProps) => ncm.competition_match_id == cm.competition_match_id)).concat(action.competition_matches)
            }
        case V1_UPDATE_COMPETITION_MATCH_MARKETS:
            return {
                ...state,
                competition_match_markets: action.offset === 0 ? action.competition_match_markets : state.competition_match_markets.filter(cmm => !action.competition_match_markets.find((ncmm:CompetitionMatchMarketProps) => ncmm.competition_match_market_id == cmm.competition_match_market_id)).concat(action.competition_match_markets)
            }
        case V1_UPDATE_COMPETITION_RECORDS:
            return {
                ...state,
                stored_records: {
                    updated: moment().milliseconds(),
                    records: state.stored_records.records.filter(cr => !action.competition_records.find((ncr:CompetitionRecordProps) => ncr.competition_record_id == cr.competition_record_id)).concat(action.competition_records)
                }
            }
        case V1_UPDATE_COMPETITION_RESULTS:
            return {
                ...state,
                stored_results : {
                    updated: moment().unix(),
                    results: state.stored_results.results.filter(cr => !action.competition_results.find((ncr:CompetitionResultProps) => ncr.competition_result_id == cr.competition_result_id)).concat(action.competition_results)
                }
            }
        case V1_UPDATE_MY_PLAYER_PICKS:
            return {
                ...state,
                my_picks: action.offset === 0 ? action.player_picks : state.my_picks.filter(mp => !action.player_picks.find((nmp:PlayerPickProps) => nmp.player_pick_id == mp.player_pick_id)).concat(action.player_picks)
            }
        case V1_UPDATE_PLAYER_PICKS:
            return {
                ...state,
                player_picks: action.player_picks
            }

        case V1_LOAD_SQUARES_COMPETITIONS:
            return {
                ...state,
                squares_competitions_list: {
                    updated: state.squares_competitions_list.updated + 1,
                    squares_competitions: action.squares_competitions
                }
            }
        case V1_UPDATE_SQUARES_COMPETITION:
            return {
                ...state,
                squares_competitions_list: {
                    updated: state.squares_competitions_list.updated + 1,
                    squares_competitions: state.squares_competitions_list.squares_competitions.filter(c => c.sq_comp_id != action.squares_competition.sq_comp_id).concat(action.squares_competition)
                }
            }


        case V1_UPDATE_BRACKET_COMPETITIONS:
            return {
                ...state,
                bracket_competitions_list: {
                    updated: state.bracket_competitions_list.updated + 1,
                    bracket_competitions: state.bracket_competitions_list.bracket_competitions.filter(bl => !action.bracket_competitions.find(nbl => nbl.bracket_competition_id == bl.bracket_competition_id)).concat(action.bracket_competitions)
                }
               
            }
        case V1_LOAD_PLAYER_BRACKETS:
            return {
                ...state,
                player_brackets_list: {
                    updated: state.player_brackets_list.updated + 1,
                    player_brackets: state.player_brackets_list.player_brackets.filter(pb => !action.player_brackets.find(npb => npb.player_bracket_id == pb.player_bracket_id)).concat(action.player_brackets)
                }
            }
        case V1_UPDATE_PLAYER_BRACKET_PICKS:
            return {
                ...state,
                player_bracket_picks_list: {
                    updated: state.player_bracket_picks_list.updated + 1,
                    player_bracket_picks: state.player_bracket_picks_list.player_bracket_picks.filter(p => !action.player_bracket_picks.find(pb => pb.player_bracket_pick_id == p.player_bracket_pick_id)).concat(action.player_bracket_picks)
                }
            }
        case V1_UPDATE_COMPETITION_PLAYER_BRACKETS:
            return {
                ...state,
                competition_player_brackets_list: {
                    updated: state.competition_player_brackets_list.updated + 1,
                    competition_player_brackets: state.competition_player_brackets_list.competition_player_brackets.filter(cpb => !action.competition_player_brackets.find(ncpb => ncpb.competition_player_bracket_id == cpb.competition_player_bracket_id)).concat(action.competition_player_brackets)
                }
            }
        case V1_UPDATE_BRACKET_COMPETITION_SCORING_RULES:
            return {
                ...state,
                bracket_competition_scoring_rules_list: {
                    updated: state.bracket_competition_scoring_rules_list.updated + 1,
                    bracket_competition_scoring_rules: state.bracket_competition_scoring_rules_list.bracket_competition_scoring_rules.filter(cpb => !action.bracket_competition_scoring_rules.find(ncpb => ncpb.bracket_competition_scoring_rule_id == cpb.bracket_competition_scoring_rule_id)).concat(action.bracket_competition_scoring_rules)
                }
            }

        default: return state
    }
}

export default compReducer