import React, { useEffect } from "react";
import { View } from 'react-native';
import { Icon } from 'react-native-elements';
import Colors from "../../constants/Colorsv2";
import { Text } from "../../globalComponents/NativeOverrides";
import { segEventTrack } from "../analytics/segment";
import { BEEventApi } from "be-components";

export function ErrorFallback(props) {
  console.log(props)
  const { error } = props;
  // Handle failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    if(error){ 
      BEEventApi.saveEvent({
        event_name: 'client_error',
        event_data: {
          error_message: error.message
        },
        level:1
      })
      segEventTrack({ event: 'Error', properties: { error:error.message } }) 
    }
    console.log(error)

  }, [error]);
  return (
    <View style={{flex:1, padding:20, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.white}}>
      <Icon name='frown' type='feather' size={75} color={Colors.utility.error} />
      <Text size={20} color={Colors.brand.midnight} textAlign='center' style={{padding:10}}>Oh Snap!</Text>
      <Text size={20} color={Colors.brand.midnight} textAlign={'center'}>An Error Occurred. If you are seeing this multiple times.  Please either clear your site history for app.bettoredge.com or delete and reinstall the app.</Text>
    </View>
  );
}

const setWithExpiry = (key:string, value:String, ttl:any) => {
    const item = {
      value: value,
      expiry: new Date().getTime() + ttl
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
  
const getWithExpiry = (key:string) => {
    const itemString = window.localStorage.getItem(key);
    if (!itemString) return null;
  
    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;
  
    if (isExpired) {
      localStorage.removeItem(key);
      return null;
    }
  
    return item.value;
  }