import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ActivityIndicator, FlatList, View } from "react-native"
import { Icon } from 'react-native-elements';
import { connect, useDispatch, useSelector } from 'react-redux';
import Colors from '../constants/Colorsv2';
import { Text, TouchableOpacity } from '../globalComponents/NativeOverrides';
import { market_getOddsLabel } from '../screensV1/Event/helpers/markets';
import { order_calcAmericanOddsFromProbability, order_calcPotentialWinnings, order_calcSaleValue, order_getHedgedPositionsFromOrder, order_getOrderFromPositionSale } from '../screensV1/Event/helpers/orders';
import { order_getActions, order_getOrderStatsFromOrders } from '../screensV1/Home/helpers/orders';
import { event_getEventByEventId, event_getMatchByMatchId, event_getOrderPrices, event_getTournamentByTournamentId } from '../store/actionsv1/events';
import { market_acceptH2HChallenge, market_cancelOrder, market_declineH2HChallenge, market_getOrderSuggestions, market_modifyOrder, market_reverseOrder, market_setDraftOrder } from '../store/actionsv1/markets';
import { player_getHelpPromptById } from '../store/actionsv1/player';
import { social_getPlayerByPlayerId } from '../store/actionsv1/social';
import { PublicPlayerProps } from '../store/types/auth_svc';
import { BestAvailableOrderProps, HedgedPositionProps, MarketProps, OrderProps, PositionProps } from '../store/types/mk_svc';
import { EventProps, LeagueProps, MatchProps, TournamentProps } from '../store/types/sr_svc';
import ActionModal from './ActionModal';
import OrderCardHeader from './OrderCardHeader';
import OrderGradeBar from './OrderGradeBar';

type OrderCardProps = {
    navigation?:any;
    order:OrderProps,
    view_mode: 'edit' | 'select' | 'view' | 'share'
    prevent_event_load?:boolean,
    hide_values?:boolean,
    compact?:boolean,
    hide_event?:boolean,
    sale_orders?:OrderProps[],
    h2h_request?:boolean
}

const OrderCard = ({ navigation, order, compact, view_mode, sale_orders, h2h_request, hide_values, hide_event, prevent_event_load }:OrderCardProps) => {
    const [ order_loaded, setOrderLoaded ] = useState(false);
    const [ positions_visible, setPositionsVisible ] = useState(false);
    const [ suggestions, setSuggestions ] = useState({
        suggestion_loading:false,
        show_suggestions: false,
        draft_probability: undefined
    })
    const [ action_visible, setActionVisible] = useState(false);
    const { suggestion_loading, show_suggestions, draft_probability } = suggestions;
    const dispatch = useDispatch();

    //Get Redux Objects for component
    let action_premium = false;
    let grade_premium = false;
    let player:PublicPlayerProps | undefined = useSelector((state:any) => state.player.player, (left, right) => left?.player_id == right?.player_id);
    const premium_order_actions = useSelector((state:any) => state.player.player_premium_items_list.player_premium_items.find(ppi => ppi.premium_item?.identifier == 'premium_order_actions'), (left, right) => left?.last_update_datetime == right?.last_update_datetime);
    const premium_grades = useSelector((state:any) => state.player.player_premium_items_list.player_premium_items.find(ppi => ppi.premium_item?.identifier == 'order_grades'), (left, right) => left?.last_update_datetime == right?.last_update_datetime);
    if(player?.type == 'premium'||premium_order_actions){ action_premium = true }
    if(player?.type == 'premium'||premium_grades){ grade_premium = true }

    
    //Get the event details
    let event:EventProps | undefined = useSelector((state:any) => state.event.events[order.event_id], (left, right) => left?.last_update_datetime == right?.last_update_datetime);
    let tournament:TournamentProps |undefined = useSelector((state:any) => state.event.tournaments[order.event_id], (left, right) => left?.last_update_datetime == right?.last_update_datetime)
    let match:MatchProps | undefined = useSelector((state:any) => state.event.matches[order.event_id], (left, right) => left?.last_update_datetime == right?.last_update_datetime)
    
    const market:MarketProps|undefined = useSelector((state:any) => state.market.markets.find(m => m.market_id == order.market_id), (left, right) => left?.market_id == right?.market_id)
    let best_available_orders:BestAvailableOrderProps[] = useSelector((state:any) => state.market.best_available[`${order.event_type}:${order.event_id}`], (left, right) => left == right);
    if(!best_available_orders){ best_available_orders = [] }
    best_available_orders = best_available_orders.filter(ba => ba.market_id == order.market_id);
    const { buy_now_price, buy_now_liquidity, jump_queue_liquidity, jump_queue_price, cash_outs } = order_getActions(market, order, best_available_orders, player?.player_id)

    const h2h_user = useSelector((state:any) => state.social.stored_players.find(p => p.player_id == order.h2h_id), (left, right) => left?.player_id == right?.player_id)
    const leagues = useSelector((state:any) => state.event.leagues, (left, right) => left?.length == right?.length)
    let league: undefined | LeagueProps
    const influencer = useSelector((state:any) => state.social.stored_players.find(p => p.player_id == order.influencer_id), (left, right) => left?.player_id == right?.player_id)

    const hedged_positions = order_getHedgedPositionsFromOrder(order)
    const delayed_cash_no_draw = hedged_positions.reduce((a,b) => a + b.delayed_cash_no_draw, 0)
    const delayed_cash_draw = hedged_positions.reduce((a,b) => a + b.delayed_cash_draw, 0)


    if(order.event_type === 'team'){ 
        league = leagues.find((l:LeagueProps) => l.league_id == event?.league_id)
    }
    if(order.event_type === 'tournament'){
        league = leagues.find((l:LeagueProps) => l.league_id == tournament?.league_id)
    }

    let order_actions = [{ type: 'share', label: 'Share Order'}, { type: 'activity', label: 'Trade Activity'}, { type: 'market', label: 'View Market' }]
    if(h2h_request){
        order_actions = [{ type: 'decline', label: 'Decline' }, { type: 'accept', label: 'Accept' }]
    }
    if(order.status === 'approved' && !h2h_request){
        order_actions.push({ type:'cancel', label: 'Cancel Remaining Unfulfilled', color:Colors.utility.error })
    }

    useEffect(() => {
        getExternalPriceFromServer()
        if(!order_loaded){
            getOrderDetailsFromServer(order.event_id)
            setOrderLoaded(true)
        }
    },[])

    const getExternalPriceFromServer = async() => {
        
        if(!player){ return }
        if(order.status != 'approved'){ return }
        //if(player.role === 'admin'){
        //await dispatch(event_getOrderPrices(order.event_id, order.event_type, order.market_id, order.side, order.side_id, 'consensus'))
        //}
    }

    const getColor = (result_ind?:string) => {
        switch(result_ind){
            case 'win': return Colors.utility.success
            case 'lose': return Colors.utility.error
            case 'draw': return Colors.brand.midnight
            default: return Colors.brand.slate
        }
    }

    /*
    const handleOrderSuggestion = async() => {
        if(suggestion_loading){ return }//Still loading
        if(false && player?.type !== 'premium'){
            return dispatch(player_getHelpPromptById('t1_order_management'))
        }
        if(show_suggestions){ 
            return setSuggestions({ ...suggestions, draft_probability: undefined, show_suggestions: false })
        }
        setSuggestions({ ...suggestions, suggestion_loading: true })
        await dispatch(market_getOrderSuggestions(order.order_id))
        setSuggestions({ ...suggestions, show_suggestions:true, suggestion_loading: false })
    }
    */

    const handleSelectSuggestion = (review_type: 'buy_now'|'jump_queue') => {
        if(review_type === 'jump_queue' && jump_queue_price){ 
            if(!action_premium){ return dispatch(player_getHelpPromptById('t2_actions_3')) }
            setSuggestions({...suggestions, draft_probability: jump_queue_price }) 
        }
        if(review_type === 'buy_now' && buy_now_price){ setSuggestions({...suggestions, draft_probability: buy_now_price }) }
        return
    }

    const handleModifyOrder = async() => {
        if(!draft_probability || suggestion_loading){ return }
        setSuggestions({ ...suggestions, suggestion_loading:true })
        await dispatch(market_modifyOrder(order.order_id, 'probability', draft_probability))
        setSuggestions({ ...suggestions, suggestion_loading: false, draft_probability:undefined, show_suggestions:false })
    }

    const handleCopyOrder = () => {
        return dispatch(market_setDraftOrder({ 
            ...order, 
            influencer_id: order.player_id,
            influence_type: 'copy',
            buy_sell_ind:'buy', 
            selling_position_id:undefined, 
            open_amt:10, 
            player_id:'', 
            h2h_id: undefined,            
            order_id:'',
            called_amt: 0,
            potential_winnings: order_calcPotentialWinnings(order.odds, 10) 
        }))
    }

    const handleFadeOrder = async() => {
        const reversed_order = await market_reverseOrder(order)
        if(!reversed_order){ return alert('Unable to fade order at this time') }
        return dispatch(market_setDraftOrder({ 
            ...reversed_order, 
            buy_sell_ind:'buy',
            influencer_id: order.player_id,
            influence_type: 'fade', 
            selling_position_id:undefined, 
            open_amt:10,
            player_id:'', 
            h2h_id:undefined,
            order_id: '', 
            called_amt: 0, 
            potential_winnings: order_calcPotentialWinnings(reversed_order.odds, 10) 
        }))
    }

    const handleSellPosition = (position:PositionProps, probability?:number, open_amt?:number) => {
        let sale_order = order_getOrderFromPositionSale(order, position, probability, open_amt)
        dispatch(market_setDraftOrder(sale_order, position))
    }

    const getEventTitle = () => {
        let title = ''
        switch(order.event_type){
            case 'team': if(event){ title = event.event_title } break;
            case 'tournament': if(tournament){ title = tournament.tournament_name } break;
            case 'match': if(match){ title = match.match_title } break;
            default: return ''
        }
        if(title.length > 40){
            title = `${title.substring(0, 37)}...`
        }
        return title
    }

    const handleOrderAction = async(action:any) => {
        switch(action.type){
            case 'cancel':
                return await dispatch(market_cancelOrder(order.order_id))
            case 'accept':
                return await dispatch(market_acceptH2HChallenge(order.order_id))
            case 'decline':
                return await dispatch(market_declineH2HChallenge(order.order_id))
            case 'market':
                if(!market){ return alert('Unable to navigation to this market.  Please try again later') }
                if(market.level === 'event'){
                    return navigation.navigate('EventStack', { screen: 'Market', initial:false, params: { event_id:order.event_id, event_type: order.event_type, market_id:order.market_id } })
                }
                return navigation.navigate('EventStack', { screen: 'Market', initial:false, params: { event_id:order.event_id, event_type: order.event_type, participant_id:order.side_id, market_id:order.market_id } })

            case 'share':
                if(!navigation){ return }
                return navigation.navigate('SocialStack', { screen:'Shareable', initial:false, params: { order_id: order.order_id } })
            case 'activity':
                return navigation.navigate('EventStack', { screen: 'OrderActivity', initial:false, params: { order_id: order.order_id } })
            default: return
        }
    }

    const shareChallenge = async() => {
        if(navigator.share){
            return navigator.share({title:'Head2Head Challenge!', text:`I'll take ${order.title} at (${market_getOddsLabel(order.odds)}). Want to take the other side?`, url: `https://share.mybe.app/h2h/${player.username}`}).then(() => console.log('shared')).catch((e) => console.log(e))
        }
        return alert('Your browser does not support sharing.  Please switch to a mobile browser')
    }


    const handleH2HResponse = async(response:string) => {
        switch(response){
            case 'decline':
                const decline_confirmed = await confirm('Are you sure you want to decline this offer?')
                if(!decline_confirmed){ return }
                return await dispatch(market_declineH2HChallenge(order.order_id))
            case 'accept':
                const accept_confirmed = await confirm('Are you sure you want to accept this offer?')
                if(!accept_confirmed){ return }
                return await dispatch(market_acceptH2HChallenge(order.order_id))
            case 'withdraw':
                const withdraw_confirmed = await confirm('Are you sure you want to accept this offer?')
                if(!withdraw_confirmed){ return }
                return await dispatch(market_cancelOrder(order.order_id))
            case 'share':
                await shareChallenge()
                return
            default: return
        }
    }

    const getOrderDetailsFromServer = async(event_id:string) => {
        if(order.event_type === 'team' && !event && !prevent_event_load){ await dispatch(event_getEventByEventId(event_id)) }
        if(order.event_type === 'tournament' && !tournament){ await dispatch(event_getTournamentByTournamentId(event_id)) }
        if(order.event_type === 'match' && !match){ await dispatch(event_getMatchByMatchId(event_id)) }
        if(order.h2h_id && !h2h_user){ await dispatch(social_getPlayerByPlayerId(order.h2h_id)) }
        if(order.influencer_id && !order.influencer){ await dispatch(social_getPlayerByPlayerId(order.influencer_id)) }
    }

    const renderPositions = (data: {item:PositionProps, index:number}) => {
        let cash_out = cash_outs.find(co => co.position_id == data.item.position_id);
        const sale_order = sale_orders?.find(o => o.selling_position_id == data.item.position_id)
        let units_for_sale = 0
        if(sale_order){ 
            units_for_sale = order_calcSaleValue(data.item.odds, data.item.in_sale_order)
        }
        if(units_for_sale == 0 && data.item.stake < 0.01){ return <></> }

        return (
            <View style={{ padding:5, backgroundColor:Colors.shades.shade100, borderRadius:4 }}>
                <View style={{ flexDirection:'row', padding:10, borderBottomWidth:1, borderColor:Colors.shades.white }}>
                    <View nativeID='position_stake'  style={{ flex:1 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>{(data.item.stake).toFixed(2)} to win {cl}{data.item.potential_winnings.toFixed(2)}</Text>
                    </View>
                    <View nativeID='position_price'>
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>{(data.item.probability * 100).toFixed(2)} ({market_getOddsLabel(data.item.odds)})</Text>
                    </View>
                </View>
                {sale_order ? 
                <View style={{ flexDirection:'row', alignItems:'center', padding:10, borderBottomWidth:1, borderBottomColor:Colors.shades.white }}>
                    <Text style={{flex:2/5}} size={14} color={Colors.brand.midnight}>FOR SALE</Text>
                    <Text style={{ flex:2/5 }} size={14} color={Colors.brand.midnight} textAlign='right'>{units_for_sale.toFixed()} for {cl}{sale_order.open_amt.toFixed(2)}</Text>
                    <TouchableOpacity style={{ flex:1/5, borderRadius:4, marginLeft:5, padding:5, backgroundColor:Colors.utility.error }} onPress={() => dispatch(market_cancelOrder(sale_order.order_id))}>
                        <Text textAlign='center' size={14} color={Colors.shades.white} weight='semibold'>CANCEL</Text>
                    </TouchableOpacity>
                </View>
                :<></>}
                {cash_out ?
                 <View style={{ padding:10, flexDirection:'row', alignItems:'center', borderBottomColor:Colors.shades.shade100, borderBottomWidth:1 }}>
                    <View style={{ flex:2, paddingRight:3, marginRight:3, borderRightWidth:1, borderColor:Colors.shades.shade600 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>Cash Out</Text>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight}><Text weight='bold' size={12} color={Colors.brand.midnight}>{(data.item.stake - cash_out.remaining_stake).toFixed(2)} of {data.item.stake.toFixed(2)}</Text> can be immediately sold now for <Text weight='bold' size={12} color={Colors.brand.midnight}>{cl}{cash_out.cash_value.toFixed(2)}</Text>.  This would lock in earnings of {cl}{cash_out.earnings.toFixed(2)}.</Text>
                    </View>
                    <View style={{ flex:1 }}>
                        <TouchableOpacity style={{ margin:5, padding:10, backgroundColor:action_premium ? Colors.incentive.gold : Colors.shades.shade100, borderRadius:4 }} onPress={() => {
                            handleSellPosition(data.item, cash_out?.cash_out_price, cash_out?.stake_reduction)
                        }}>
                            <Text size={14} color={Colors.shades.white} textAlign='center' weight='bold'>{cl}{cash_out.cash_value.toFixed(2)}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                :<></>}
                <View style={{ padding:10, flexDirection:'row', alignItems:'center' }}>
                    <View style={{ flex:2, paddingRight:3, marginRight:3, borderRightWidth:1, borderColor:Colors.shades.shade600 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>Sell Position</Text>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight}>Create a sell limit order with this position and receive cash if the order is fulfilled.</Text>
                    </View>
                    <View style={{ flex:1 }}>
                        <TouchableOpacity style={{ margin:5, padding:10, backgroundColor:Colors.brand.electric, borderRadius:4 }} onPress={() => handleSellPosition(data.item)}>
                            <Text size={14} color={Colors.shades.white} textAlign='center'>Sell</Text>                          
                        </TouchableOpacity>
                    </View>
                </View>

            </View>
        )
    }

    const order_stats = order_getOrderStatsFromOrders([order])
    const order_stat = order_stats[0]
    const cl = order.market_type === 'FREE' ? 'E' : '$'
    let draft_odds = order_calcAmericanOddsFromProbability(draft_probability??0)

    if(compact){
        return (
            <View style={{ flexDirection:'row', backgroundColor:Colors.shades.white, padding:5 }}>
                <View style={{ flex:1/8, justifyContent:'center', margin:3, padding:5, borderRadius:4, backgroundColor: getColor(order_stat.result_ind) }} nativeID='order_status'>
                    {order.resolution_status == 'inprogress' && !order_stat.result_ind ?
                    <Icon name='clock' type='feather' size={18} color={Colors.shades.white} />
                    :
                    <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>{order_stat.result_ind == 'win' ? 'W' : order_stat.result_ind == 'lose' ? 'L' : 'D'}</Text>
                    }
                </View>
                <View nativeID='order_outcome' style={{ flex:4/8, margin:2, padding:2 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='semibold'>{order.title}</Text>
                    <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>{getEventTitle()}</Text>
                </View>
                <View nativeID='order_odds' style={{ flex:1/8, justifyContent:'center' }}>
                    <Text size={14} textAlign='center' weight='semibold'>{market_getOddsLabel(order.odds)}</Text>
                </View>
                {order_stat.result_ind ?
                <View nativeID='order_result' style={{ flex:2/8, alignItems:'flex-end', justifyContent:'center' }}>
                    <Text size={14} color={getColor(order_stat.result_ind)}>{order_stat.result_ind.toUpperCase()}</Text>
                    {!hide_values ?
                    <Text style={{ marginTop:2 }} size={14} color={getColor(order_stat.result_ind)} weight='semibold'>{cl}{(order_stat.cash_rcvd + order_stat.winnings).toFixed(2)}</Text>
                    :<></>}
                </View>
                :
                <View nativeID='order_non_result' style={{ flex:2/8, alignItems:'flex-end', justifyContent:'center' }}>
                    <Text size={14} color={Colors.brand.slate} weight='regular'>UPCOMING</Text>
                    {!hide_values ?
                    <Text style={{ marginTop:2 }} size={14} color={Colors.brand.midnight} weight='bold'>{cl}{(order.open_amt + order.called_amt).toFixed(2)}</Text>
                    :<></>}
                </View>
                }
            </View>
        )
    }

    return (
        <View style={{ borderRadius: 10, backgroundColor: Colors.shades.white, shadowColor: "rgba(0, 0, 0, 0.12)", shadowOffset: {  width: 0,height: 10 }, shadowRadius: 10, shadowOpacity: 1 }}>
            {!order_loaded ?
                <View style={{ height:200 }} />
            :
            <View>
                <View>
                    <View style={{ padding:10, paddingBottom:0 }}>
                        <OrderCardHeader result_ind={order_stat.result_ind} order={{ ...order, h2h_user:h2h_user, influencer }} hide_actions={view_mode !== 'edit'} onActionPress={() => setActionVisible(true)}/>
                    </View>
                <View nativeID='order_details' style={{ padding:15, paddingBottom:0, borderBottomWidth:1, borderBottomColor:Colors.shades.shade100 }}>
                    {order.tip_pct && view_mode === 'edit' ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1/3 }} size={14} color={Colors.brand.midnight}>Tip Percent If Wins</Text>
                        <Text style={{ flex:2/3 }} size={14} color={Colors.highlights.highlight400} weight='bold' textAlign='right'>{(order.tip_pct*100).toFixed(2)}%</Text>
                    </View>
                    :<></>}
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1/3 }} size={14} color={Colors.brand.midnight}>Outcome</Text>
                        <Text style={{ flex:2/3 }} size={14} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{order.title}</Text>
                    </View>
                    {order.resolution_status === 'inprogress' ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1/3 }} size={14} color={Colors.brand.midnight}>Probability (Odds)</Text>
                        <Text style={{ flex:2/3 }} size={14} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{(order.probability * 100).toFixed(2)}% ({market_getOddsLabel(order.odds)})</Text>
                    </View>
                    :<></>}
                    {order.resolution_status === 'closed' ? 
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1/3 }} size={14} color={Colors.brand.midnight}>Probability (Odds)</Text>
                        <Text style={{ flex:2/3 }} size={14} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{(order_stat.resolved_position_probability * 100).toFixed(2)}% ({market_getOddsLabel(order_stat.resolved_position_odds)})</Text>
                    </View>
                    :<></>}
                    { order.status == 'approved' && order.grade && grade_premium ?
                     <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1/3 }} size={14} color={Colors.brand.midnight}>Grade</Text>
                        <View style={{ flex:2/3, alignItems:'flex-end' }}>
                            <OrderGradeBar grade={order.grade} view_type='card'/>
                        </View>
                    </View>
                    :<></>}
                    {view_mode === 'edit' && !order.h2h_id ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1/3 }} size={14} color={Colors.brand.midnight}>Collar Pct</Text>
                        <Text style={{ flex:2/3 }} size={14} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{(order.collar_pct*100)}%</Text>
                    </View>
                    :<></>}
                    {false && order.commission_pct && view_mode == 'edit' ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1/3 }} size={14} color={Colors.brand.midnight}>Commission</Text>
                        <Text style={{ flex:2/3 }} size={14} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{(order.commission_pct*100)}%</Text>
                    </View>
                    :<></>}
                    {order.status === 'approved' && view_mode === 'edit' && order.resolution_status === 'inprogress' ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1/3 }} size={14} color={Colors.brand.midnight}>{order.h2h_id?'Challenge':'Order'} amount</Text>
                        <Text style={{ flex:2/3 }} size={14} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{cl}{(order.open_amt + order.called_amt).toFixed(2)}</Text>
                    </View>
                    :<></>}
                    {view_mode === 'edit' && order.resolution_status === 'closed' ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight}>{order.h2h_id?'Challenge':'Purchased'} amount</Text>
                        <Text size={14} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{cl}{(order_stat.original_stake).toFixed(2)}</Text>
                    </View>
                    :<></>}
                    { order.resolution_status === 'inprogress' && order.called_amt > 0 && (view_mode === 'edit' || (view_mode === 'share' && !hide_values)) ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1/3 }} size={14} color={Colors.brand.midnight}>{order.h2h_id?'Challenged':'Fulfilled'}</Text>
                        <Text style={{ flex:2/3 }} size={14} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{cl}{(order.called_amt).toFixed(2)}</Text>
                    </View>
                    :<></>}
                    {order.status === 'approved' && view_mode === 'edit' && !order.h2h_id ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1/3 }} size={14} color={Colors.brand.midnight}>Unfulfilled</Text>
                        <Text style={{ flex:2/3 }} size={14} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{cl}{(order.open_amt).toFixed(2)}</Text>
                    </View>
                    :<></>}
                    { order.resolution_status === 'closed' && view_mode === 'edit' && !hide_values && (view_mode === 'share' && !hide_values) ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1/3 }} size={14} color={Colors.brand.midnight}>Total Position</Text>
                        <Text style={{ flex:2/3 }} size={14} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{cl}{(order_stat.resolved_stake).toFixed(2)}</Text>
                    </View>
                    :<></>}
                    { order.resolution_status === 'closed' && view_mode === 'edit' && !hide_values || (view_mode === 'share' && !hide_values) ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1/3 }} size={14} color={Colors.brand.midnight}>Winnings</Text>
                        <Text style={{ flex:2/3 }} size={14} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{cl}{(order_stat.winnings).toFixed(2)}</Text>
                    </View>
                    :<></>}
                     { order.resolution_status === 'closed' && order_stat.result_ind == 'win' && view_mode === 'edit' && order_stat.net_winnings < order_stat.winnings ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.slate}>Processing Charge</Text>
                        <Text size={14} color={Colors.brand.slate} weight='regular' textAlign='right'>{cl}{(order_stat.winnings - order_stat.net_winnings).toFixed(2)}</Text>
                    </View>
                    :<></>}
                    {order_stat.cash_rcvd > 0 && view_mode === 'edit' || (order_stat.cash_rcvd > 0 && view_mode === 'share' && !hide_values) ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1/3 }} size={14} color={Colors.brand.midnight}>{order.market_type === 'FOR_MONEY'?'Cash':'Coins'} Received</Text>
                        <Text style={{ flex:2/3 }} size={14} color={Colors.highlights.highlight400} weight='bold' textAlign='right'>{cl}{(order_stat.cash_rcvd).toFixed(2)}{order_stat.delayed_cash?` (+${order_stat.delayed_cash.toFixed(2)})`:''}</Text>
                    </View>
                    :<></>}
                    {order.resolution_status === 'inprogress' && delayed_cash_draw > 0 ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1/2 }} size={14} color={Colors.brand.midnight}>Addt Cash If Draw / No Bet</Text>
                        <Text style={{ flex:1/2 }} size={14} color={Colors.highlights.highlight500} weight='semibold' textAlign='right'>{cl}{(delayed_cash_draw).toFixed(2)}</Text>
                    </View>
                    :<></>}
                    {order.resolution_status === 'inprogress' && delayed_cash_no_draw > 0 ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1/2 }} size={14} color={Colors.brand.midnight}>Addt Cash If Graded</Text>
                        <Text style={{ flex:1/2 }} size={14} color={Colors.highlights.highlight500} weight='semibold' textAlign='right'>{cl}{(delayed_cash_no_draw).toFixed(2)}</Text>
                    </View>
                    :<></>}
                    {order.status === 'approved' && view_mode === 'edit' ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:8}}>
                        <Text style={{ flex:1/3 }} size={14} color={Colors.brand.midnight}>Expires</Text>
                        <Text style={{ flex:2/3 }} size={14} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{moment(order.expire_datetime).fromNow()}</Text>
                    </View>
                    :<></>}
                    {view_mode == 'edit' && h2h_request ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginTop:5, marginBottom:8}}>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight}>Respond</Text>
                        <View style={{ flex:1.5, flexDirection:'row' }}>
                            <TouchableOpacity style={{ flex:1, padding:10, backgroundColor:Colors.utility.error, borderRadius:4 }} onPress={() => handleH2HResponse('decline')}>
                                <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>DECLINE</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ flex:1, padding:10, marginLeft:5, backgroundColor:Colors.utility.success, borderRadius:4 }} onPress={() => handleH2HResponse('accept')}>
                                <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>ACCEPT</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    :<></>}
                    {view_mode == 'edit' && !h2h_request && order.status == 'approved' && order.h2h_id ?
                    <View style={{ flexDirection:'row', alignItems:'center', marginTop:5, marginBottom:8}}>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight}>Action</Text>
                        <View style={{ flex:1.5, flexDirection:'row' }}>
                            <TouchableOpacity style={{ flex:1, padding:10, backgroundColor:Colors.utility.error, borderRadius:4 }} onPress={() => handleH2HResponse('withdraw')}>
                                <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>WITHDRAW</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ flex:1, padding:10, marginLeft:5, backgroundColor:Colors.brand.electric, borderRadius:4 }} onPress={() => handleH2HResponse('share')}>
                                <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>NUDGE</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    :<></>}
                </View>
                <View>
                    {order.status === 'approved' && view_mode === 'edit' && !order.h2h_id ?
                     <View style={{ backgroundColor:Colors.incentive.gold_faded }}>
                        {!draft_probability ?
                        <View>                            
                            {jump_queue_price ?
                            <View style={{ padding:10, flexDirection:'row', alignItems:'center' }}>
                                <View style={{ flex:3, paddingRight:3, marginRight:3, borderRightWidth:1, borderColor:Colors.shades.shade600 }}>
                                    <Text size={14} color={Colors.brand.midnight} weight='bold'>Jump Queue</Text>
                                    <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight}>There are orders available that are cheaper than yours. Modify this order's price to be the cheapest available.</Text>
                                </View>
                                <View style={{ flex:1 }}>
                                    {action_premium ?
                                    <Text size={12} weight='semibold' color={Colors.brand.midnight} textAlign='center'>{(jump_queue_price * 100).toFixed(2)}% ({market_getOddsLabel(order_calcAmericanOddsFromProbability(jump_queue_price))})</Text>
                                    :<></>}
                                    <TouchableOpacity style={{ margin:5, padding:10, backgroundColor:action_premium ? Colors.incentive.gold : Colors.shades.shade100, borderRadius:4 }} onPress={() => handleSelectSuggestion('jump_queue')}>
                                        {action_premium ?
                                        <Text size={14} color={Colors.shades.white} textAlign='center'>Review</Text>
                                        :
                                        <Icon name='lock' size={14} color={Colors.incentive.gold} type='feather' />
                                        }
                                    </TouchableOpacity>
                                </View>
                            </View>
                            :
                            <View style={{ flexDirection:'row', alignItems:'center', padding:10, marginBottom:5 }}>
                                 <Icon name='check-circle' type='feather' color={Colors.utility.success} size={16} />
                                <Text style={{ marginLeft:10 }} size={14} color={Colors.brand.midnight} weight='bold'>Your order is currently the best available</Text>
                            </View>
                            }
                            {buy_now_price ?
                            <View style={{ padding:10, flexDirection:'row', borderTopWidth:1, borderColor:Colors.shades.shade600 }}>
                                <View style={{ flex:3, paddingRight:3, marginRight:3, borderRightWidth:1, borderColor:Colors.shades.shade600 }}>
                                    <Text size={14} color={Colors.brand.midnight} weight='bold'>Buy Now</Text>
                                    <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight}>Get this fulfilled now by modifying the order to match the cheapest available price.</Text>
                                </View>
                                <View style={{ flex:1, marginTop:5 }}>
                                    <Text size={12} weight='semibold' color={Colors.brand.midnight} textAlign='center'>{(buy_now_price * 100).toFixed(2)}% ({market_getOddsLabel(order_calcAmericanOddsFromProbability(buy_now_price))})</Text>
                                    <TouchableOpacity style={{ margin:5, padding:10, backgroundColor:Colors.brand.midnight, borderRadius:4 }} onPress={() => handleSelectSuggestion('buy_now')}>
                                        <Text size={14} color={Colors.shades.white} textAlign='center'>Review</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                            :<></>}
                        </View>
                        : draft_probability ?
                        <View>
                            <Text style={{ padding:5, paddingLeft:10 }} size={14} color={Colors.brand.midnight} weight='bold'>Review And Confirm Changes</Text>
                            <View style={{ padding:10 }}>
                                <View style={{ flexDirection: 'row', alignItems:'center', paddingTop:10, borderTopColor:Colors.shades.shade600, borderTopWidth:1 }}>
                                    <Text style={{flex:1/2}} size={14} weight='regular' color={Colors.brand.midnight}>Updated Odds</Text>
                                    <View style={{ flex:1/2, flexDirection:'row', alignItems:'center', justifyContent:'flex-end' }}>
                                        <Text size={14} color={Colors.brand.midnight}>{market_getOddsLabel(order.odds)}</Text>
                                        <Icon style={{ margin:5 }} name='arrow-right' type='feather' size={14} color={Colors.brand.midnight} />
                                        <Text size={14} color={Colors.brand.midnight} weight='semibold'>{market_getOddsLabel(order_calcAmericanOddsFromProbability(draft_probability))}</Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row', alignItems:'center', marginTop:5 }}>
                                    <Text style={{flex:1/2}} size={14} weight='regular' color={Colors.brand.midnight}>Updated Winnings</Text>
                                    <View style={{ flex:1/2, flexDirection:'row', alignItems:'center', justifyContent:'flex-end' }}>
                                        <Text size={14} color={Colors.brand.midnight}>{cl}{order.potential_winnings.toFixed(2)}</Text>
                                        <Icon style={{ margin:5 }} name='arrow-right' type='feather' size={14} color={Colors.brand.midnight} />
                                        <Text size={14} color={Colors.brand.midnight} weight='semibold'>{cl}{order_calcPotentialWinnings(draft_odds, order.open_amt).toFixed(2)}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{flexDirection:'row', alignItems:'center', padding:10}}>
                                <TouchableOpacity native_id='order_update_cancel' style={{ flex:1, padding:10, borderWidth:1, borderColor:Colors.brand.midnight, borderRadius:4 }} onPress={() => setSuggestions({ ...suggestions, draft_probability: undefined })}>
                                    <Text size={14} color={Colors.brand.midnight} weight='semibold' textAlign='center'>Cancel</Text>
                                </TouchableOpacity>
                                <TouchableOpacity native_id='order_update_confirm' style={{ flex:2, padding:10, backgroundColor:Colors.utility.success, borderWidth:1, borderColor:Colors.utility.success, borderRadius:4, marginLeft:5 }} onPress={() => handleModifyOrder()}>
                                    {suggestion_loading ?
                                    <ActivityIndicator style={{ alignSelf:'center' }} size='small' color={Colors.shades.white} />
                                    :
                                    <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>Confirm</Text>
                                    }
                                </TouchableOpacity>
                            </View>
                        </View>
                        :<></>
                        }
                    </View>
                    :<></>}
                    {order_stat.result_ind ?
                    <View style={{ padding:10, paddingLeft:15, paddingRight:15, flexDirection:'row', alignItems:'center'}}>
                        <Text style={{ flex:1/3 }} size={14} color={Colors.brand.midnight}>Result</Text>
                        {order.no_bet && order.no_bet_reason ?
                        <Text style={{ flex:2/3 }} size={14} color={getColor(order_stat.result_ind)} weight='semibold' textAlign='right'>{order.no_bet_reason}</Text>
                        :
                        <Text style={{ flex:2/3 }} size={14} color={getColor(order_stat.result_ind)} weight='semibold' textAlign='right'>{order_stat.result_ind.toUpperCase()}</Text>
                        }
                    </View>
                    :<></>}
                </View>
                {!hide_event ?
                <View nativeID='event_info' style={{ padding:10 }}>
                    {order.event_type === 'team' && event ?
                    <View nativeID='event_details' style={{ flexDirection:'row', alignItems:'center', marginTop:5 }}>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight}>{league?.league_name?`${league.league_name}: `:''}{event.event_title}</Text>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.cyan} weight='semibold' textAlign='right'>{event.time_detail === 'scheduled'? moment(event.scheduled_datetime).format('MM/DD @ hh:mm a'):event.time_detail}</Text>
                    </View>
                    :<></>}
                    {order.event_type === 'tournament' && tournament ?
                    <View nativeID='tournament_details' style={{ flexDirection:'row', alignItems:'center', marginTop:5 }}>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight}>{tournament.tournament_name}</Text>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.cyan} weight='semibold' textAlign='right'>{league?.league_name?`${league.league_name}: `:''}{tournament.time_detail}</Text>
                    </View>
                    :<></>}
                    {order.event_type === 'match' && match ?
                    <View nativeID='match_details' style={{ flexDirection:'row', alignItems:'center', marginTop:5 }}>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight}>{match.match_title}</Text>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.cyan} weight='semibold' textAlign='right'>{moment(match.scheduled_datetime).format('ddddd')}</Text>
                    </View>
                    :<></>}
                </View>
                :<></>}
                { view_mode === 'edit' ? 
                <ActionModal 
                    isVisible={action_visible} 
                    closeModal={() => setActionVisible(false)} 
                    onSelectOption={(option) => handleOrderAction(option)}
                    options={order_actions} 
                    label_attribute='label' />
                :<></>}
                { order.resolution_status === 'inprogress' && view_mode === 'edit' && !order.h2h_id && order.positions[0] && order.positions.length > 0?
                <View style={{ padding:10, borderTopWidth:1, borderColor:Colors.shades.shade600 }}>
                    <TouchableOpacity style={{ padding:10, flexDirection:'row', alignItems:'center' }} onPress={() => setPositionsVisible(!positions_visible)}>
                        <View style={{ flex:1 }}>
                            <Text size={14} weight='semibold' color={Colors.brand.midnight}>Positions</Text>
                            <View style={{ marginTop:3, flexDirection:'row', alignItems:'center' }}>
                                <Text size={12} color={Colors.brand.midnight}>{order_stat.unresolved_stake.toFixed(2)} to win {cl}{order_stat.unresolved_potential_winnings.toFixed(2)}</Text>
                                <Text style={{ marginLeft:5 }} size={12} color={Colors.brand.slate}>Est. Earnings {cl}{(order_stat.unresolved_potential_net_winnings - order_stat.unresolved_stake).toFixed(2)}</Text>
                            </View>

                        </View>
                        <View style={{ flexDirection:'row', alignItems:'center' }}>
                            {cash_outs.length > 0 ?
                            <Text style={{ marginRight:5 }} size={14} weight='semibold' color={Colors.incentive.gold}>Cash Out Available</Text>
                            :<></>}
                            <Icon name={positions_visible?'chevron-down':'chevron-right'} type='feather' size={16} color={Colors.brand.midnight} />
                        </View>
                    </TouchableOpacity>
                    {positions_visible ?
                    <FlatList data={order.positions} renderItem={renderPositions} keyExtractor={(item) => item.position_id.toString()} />
                    :<></>}
                </View>
                :<></>}
                { false && order.resolution_status === 'inprogress' && view_mode === 'share' && !hide_values && order.positions[0] && order.positions.length > 0 && order.resolution_status === 'inprogress' ?
                <View style={{ padding:10, borderTopWidth:1, borderColor:Colors.shades.shade600 }}>
                    <Text size={14} weight='semibold' color={Colors.brand.midnight}>Positions</Text>
                    <FlatList data={order.positions} renderItem={renderPositions} keyExtractor={(item) => item.position_id.toString()} />
                </View>
                :<></>}
                </View>
                { view_mode === 'view' && order.resolution_status === 'inprogress' ?
                <View style={{ flexDirection:'row', alignItems:'center', justifyContent:'space-between' }}>
                    <TouchableOpacity style={{ flex:1, backgroundColor:Colors.brand.midnight, borderBottomLeftRadius:10 }} onPress={() => handleCopyOrder()}>
                        <Text style={{padding:10}} size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>COPY</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ flex:1, backgroundColor:Colors.brand.cyan, borderBottomRightRadius:10 }} onPress={() => handleFadeOrder()}>
                        <Text style={{padding:10}} size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>FADE</Text>
                    </TouchableOpacity>
                </View>
                :<></>}
            </View>
            }
        </View>
    )
}

export default OrderCard