import { Checkout, Components } from 'be-components';
import React from 'react';
import { View } from "react-native"
import Colors from '../constants/Colorsv2';
import { useDispatch, useSelector } from 'react-redux';
import { V1_SHOW_CHECKOUT, V1_SHOW_WALLET } from '../store/actionsv1/types';
import { useSafeAreaInsets } from 'react-native-safe-area-context';



const CheckoutHolder = ({}) => {
    const dispatch = useDispatch()
    const show_checkout = useSelector((state:any) => state.util.show_checkout, (left, right) => left == right);
    const app_size = useSelector((state:any) => state.util.app_size, (left, right) => left?.height == right?.height);
    const insets = useSafeAreaInsets();
    const alignment = app_size.width > 500 ? 'center' : 'flex-end'
    const width = app_size.width > 500 ? 500 : app_size.width
    if(!show_checkout.visible || !show_checkout.draft_order){ return <></> }

    const checkout_height = app_size.height - insets.bottom - insets.top
    return (
        <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:alignment, alignItems: 'center' }}>
            <Components.Spring
                to={0}
                from={app_size.height}
                slide='vertical'
                style={{ 
                    borderTopRightRadius:alignment=='center'?22:0, 
                    borderTopLeftRadius:alignment=='center'?22:0,
                    borderBottomLeftRadius:alignment=='center'?22:0,
                    borderBottomRightRadius:alignment=='center'?22:0,
                    backgroundColor:Colors.shades.shade100, 
                    alignItems:'center',
                    width,
                    minHeight:500,
                    paddingTop:insets.top,
                    paddingBottom:insets.bottom
                }}
            >

                <Checkout
                    item_order={show_checkout.draft_order}
                    height={checkout_height}
                    account_id={show_checkout.account_id}
                    onRequestNewAccount={() => {
                        dispatch({ type: V1_SHOW_CHECKOUT, show_checkout: { ...show_checkout, visible:false } })
                        dispatch({ type: V1_SHOW_WALLET, show_wallet: { visible:true } })
                    }}
                    onCancel={() => dispatch({ type: V1_SHOW_CHECKOUT, show_checkout:{ visible: false } })}
                    onCompleteOrder={() => {
                        dispatch({ type:V1_SHOW_WALLET, show_wallet: { visible:false } })
                        dispatch({ type: V1_SHOW_CHECKOUT, show_checkout: { visible:false } })
                    }}
                />
            </Components.Spring>
        </View>
    )
}

export default CheckoutHolder