import React, { useEffect, useState } from 'react'
import { ActivityIndicator, FlatList, ImageBackground, ScrollView, TextInput, View } from "react-native"
import { Text, TouchableOpacity } from '../../globalComponents/NativeOverrides'
import Colors from '../../constants/Colorsv2'
import { AccountProps, ItemOrderProps, ItemProps, RecurringOrderProps } from '../../store/types/pay_svc'
import { wallet_cancelRecurringOrder, wallet_getItemsByCatalogId, wallet_getMyAccounts, wallet_getMyRecurringOrders, wallet_restoreRecurringOrder, wallet_updateRecurringOrder } from '../../store/actionsv1/wallet'
import { useDispatch, useSelector } from 'react-redux'
import { V1_LOAD_ALERT, V1_SHOW_CHECKOUT, V1_UPDATE_ITEM_ORDER, V1_UPDATE_WALLET_VISIBILE } from '../../store/actionsv1/types'
import { Icon } from 'react-native-elements';
import moment from 'moment'
import { PremiumAPI, PremiumAnalytics, PremiumAnonymous, PremiumComps, PremiumDashboard, PremiumDeposits, PremiumEarnings, PremiumFilters, PremiumNoAds, PremiumOrderGrades, PremiumOrders, PremiumPriceComparison, PremiumReferral, PremiumSocial, PremiumSupport } from '../../Componentsv1/Icons'
import { player_getHelpPromptById } from '../../store/actionsv1/player'
import { PlayerPremiumItem, PremiumItemProps } from '../../store/types/auth_svc'

/*
Grab the recurring orders by a player.  What could happen?
1) The player has an active recurring order for a premium item and that premium item is currently visible
    - Here we can offer a Change Plan button
2) The player has an active recurring order for a premium item but that item is not visible
    - Here we can offer a switch to this plan button
3) The does not have an active plan but the next order date is in the future (indicating that the plan has been canceled but still active)
    - Here we can offer a re-activate button
*/

export const getIconByFeature = (pi:PremiumItemProps) => {
    switch(pi.icon_name){
        case 'order_grades': return <PremiumOrderGrades size={30} color={Colors.incentive.gold}/>
        case 'advanced_market_analytics': return  <PremiumAnalytics size={30} color={Colors.incentive.gold}/>
        case 'advanced_filtering': return <PremiumFilters size={30} color={Colors.incentive.gold}/>
        case 'additional_earnings': return <PremiumEarnings size={30} color={Colors.incentive.gold}/>
        case 'referral_rewards': return <PremiumReferral size={30} color={Colors.incentive.gold}/> 
        case 'external_pricing': return <PremiumPriceComparison size={30} color={Colors.incentive.gold}/>
        case 'premium_order_actions': return <PremiumOrders size={30} color={Colors.incentive.gold}/>
        case 'deposit_advantages': return <PremiumDeposits size={30} color={Colors.incentive.gold}/>
        case 'additional_insights': return <PremiumDashboard size={30} color={Colors.incentive.gold}/>
        case 'anonymous_mode': return <PremiumAnonymous size={30} color={Colors.incentive.gold}/>
        case 'premium_social': return <PremiumSocial size={30} color={Colors.incentive.gold}/>
        case 'premium_apis': return <PremiumAPI size={30} color={Colors.incentive.gold}/>
        case 'premium_competitions': return <PremiumComps size={30} color={Colors.incentive.gold}/>
        case 'priority_support': return <PremiumSupport size={30} color={Colors.incentive.gold}/>
        case 'less_ads': return <PremiumNoAds size={30} color={Colors.incentive.gold}/>
        default: return <PremiumDashboard size={30} color={Colors.incentive.gold}/>
    }

}


/*
<View style={{ flexDirection:'row', padding:10, backgroundColor:Colors.shades.shade100, borderRadius:8 }}>
                    <Text size={14} color={Colors.brand.midnight}>A La Carte Price</Text>
                    <View style={{ flexDirection:'row' }}>
                        <TouchableOpacity style={{ borderRadius:8, padding:10, backgroundColor:Colors.brand.midnight }}>
                            <Text size={12} color={Colors.shades.white}>${data.item.daily_price}</Text>
                            <Text style={{ marginTop:2 }} size={12} color={Colors.shades.white}>24 Hours</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ borderRadius:8, padding:10, backgroundColor:Colors.brand.midnight, marginLeft:5 }}>
                            <Text size={12} color={Colors.shades.white}>${data.item.monthly_price}</Text>
                            <Text style={{ marginTop:2 }} size={12} color={Colors.shades.white}>One - Month</Text>
                        </TouchableOpacity>
                    </View>
                </View>
*/



const PremiumScreen = ({ navigation, appHeight, insets }:any) => {
    const [ loading, setLoading ] = useState(false);
    const [ my_recurring_order, setMyRecurringOrder ] = useState<RecurringOrderProps|undefined>(undefined);
    const [ change_plan, setChangePlan ] = useState<RecurringOrderProps | undefined>(undefined);
    const [ select_plan, setSelectPlan ] = useState(false);
    const [ accounts, setAccounts ] = useState<AccountProps[]>([]);
    const [ confirm_cancel, setConfirmCancel ] = useState(false);
    const [ select_account, setSelectAccount ] = useState(false);
    const [ feature_offset, setFeatureOffset ] = useState(0);
    const [ premium_items, setPremiumItems ] = useState<ItemProps[]>([])
    const [ premium_item, setPremiumItem ] = useState<ItemProps|undefined>(undefined)
    const [ free_trial_agree, setFreeTrialAgree ] = useState(false);
    const [ a_la_carte_disclaimer, setALaCarteDiscliamer ] = useState(false);
    const [ a_la_carte_item, setALaCarteItem ] = useState<PremiumItemProps|undefined>(undefined)
    const [ selected_price, setSelectedPrice ] = useState<'day'|'month'|undefined>(undefined)
    const [ agreed_to_terms, setAgreedToTerms ] = useState(false);

    const selected_account = accounts.find(a => a.account_id == change_plan?.account_id);
    
    const player = useSelector((state:any) => state.player.player, (left, right) => left?.updated == right?.updated);
    const app_size:{ width:number, height:number } = useSelector((state:any) => state.util.app_size, (left, right) => left.width == right.width);
    //Get the recurring orders related to the premium items we have

    const premium_features:PremiumItemProps[] = useSelector((state:any) => state.player.premium_items, (left, right) => left.length == right.length);
    const player_premium_items_list: { updated:number, player_premium_items:PlayerPremiumItem[] } = useSelector((state:any) => state.player.player_premium_items_list, (left, right) => left.updated == right.updated);
    const player_premium_items = player_premium_items_list.player_premium_items.filter(ppi => ppi.status == 'active' && moment().isBefore(moment(ppi.expire_datetime)));
    const features = premium_features.sort((a,b) => a.priority - b.priority).slice(feature_offset*4, (feature_offset * 4) + 4)

    //Get the bullet to generate navigating
    let bullets:string[] = []
    for (let i = 0; i < Math.ceil(premium_features.length / 4); i++) {
        bullets.push(i.toString())
    }

    const dispatch = useDispatch();

    useEffect(() => {
        if(!player?.player_id){ return }
        getPremiumItemsFromServer()
    },[player?.player_id])

    /**
     * Get data from server initially to load screen and set up the appropriate settings available
     */
    const getPremiumItemsFromServer = async() => {
        setLoading(true);
        let ro:RecurringOrderProps|undefined = undefined;
        let items = await wallet_getItemsByCatalogId('5');

        if(player){
            let accts = await wallet_getMyAccounts();
            accts = accts.filter(a => a.status == 'active');
            //Need to filter to only eligible accounts for subscriptions
            setAccounts(accts.filter(a => {
                if(a.account_type == 'card'){ return a }
                //if(a.account_type == 'ach' && a.ach_detail?.ach_type == 'manual'){ return a }
                //if(a.account_type == 'bettoredge'){ return a }

            }))
            let ros = await wallet_getMyRecurringOrders();
            ro = ros.find(ro => items.map(i => i.item_id.toString()).includes(ro.item_id));
            setMyRecurringOrder(ro)      
        }
        //Filter down the recurring orders to only those related to premium items
        if(ro){
            //This user has already gotten the free trial - we need to now remove it as an option
            items = items.filter(i => i.identifier != 'week_premium_free');
            setPremiumItem(items[0]);
        } else {
            let free_trial = items.find(i => i.identifier == 'week_premium_free');
            setPremiumItem(free_trial)
        }
        setPremiumItems(items);
        setLoading(false);
    }

    const handleCancelSubscription = async() => {
        if(!change_plan){ return }
        //Update recurring order
        const new_ro = await wallet_cancelRecurringOrder(change_plan.recurring_order_id)
        if(!new_ro){ return dispatch({ type: V1_LOAD_ALERT, alert: { type: 'error', title: 'Unable to process request.  Please try again.' } }) }
        setChangePlan(undefined);
        dispatch({ type: V1_LOAD_ALERT, alert: { type: 'success', title: `Subscription successfully cancelled.`,  body:`You will remain a premium member through ${moment(new_ro.next_order_datetime).format('MMM DD YYYY hh:mm a')}. You can restore from here at anytime.` } })
        return setMyRecurringOrder(new_ro);
    }

    const handleRestoreSubscription = async() => {
        if(!change_plan){ return }
        const new_ro = await wallet_restoreRecurringOrder(change_plan);
        if(!new_ro){ return dispatch({ type: V1_LOAD_ALERT, alert: { type: 'error', title: 'Unable to process request.  Please try again.' } }) }
        dispatch({ type: V1_LOAD_ALERT, alert: { type: 'success', title: 'Subscription successfully restored' } })
        setMyRecurringOrder(new_ro)
        setChangePlan(undefined)
    }

    const handleUpdateSubscription = async() => {
        if(!change_plan){ return }
        const new_ro = await wallet_updateRecurringOrder(change_plan);
        if(!new_ro){ return dispatch({ type: V1_LOAD_ALERT, alert: { type: 'error', title: 'Unable to process request.  Please try again.' } }) }
        dispatch({ type: V1_LOAD_ALERT, alert: { type: 'success', title: 'Subscription successfully updated' } })
        setMyRecurringOrder(new_ro)
        return setChangePlan(undefined)
    }

    const handleBuy = async(item:ItemProps) => {
        let item_order:ItemOrderProps = {
            item_order_id: '',
            player_id: '',
            status: 'pending',
            region: '',
            type: 'store',
            location: {},
            restricted_account_types: item.restricted_account_types,
            description: item.description,
            items: [{
                item_id: item.item_id,
                quantity:1,
                amount: item.price
            }],
            amount: item.price,
            total_amount: item.price,
            create_datetime: '', last_update_datetime: '',

        }
        dispatch({ type: V1_SHOW_CHECKOUT, show_checkout: { visible:true, draft_order:item_order } })
        //dispatch({ type: V1_UPDATE_WALLET_VISIBILE, visible: true })
    }


    const renderFeatures = ( data: { item:PremiumItemProps, index:number } ) => {
        const a_la_carte_selected = data.item.premium_item_id == a_la_carte_item?.premium_item_id ? true : false
        const player_premium_item = player_premium_items.find(ppi => ppi.premium_item_id == data.item.premium_item_id);
        return (
            <View style={{ padding:10, marginBottom:15, borderRadius:4, backgroundColor: a_la_carte_selected?Colors.shades.shade100:undefined }}>
                <TouchableOpacity style={{ flexDirection:'row' }} onPress={() => {
                    if(!player){ return dispatch(player_getHelpPromptById(data.item.client_native_id))  }
                    if(player.type == 'premium'){ return dispatch(player_getHelpPromptById(data.item.client_native_id)) }
                    if(data.item.a_la_carte){
                        if(player_premium_item){ return dispatch(player_getHelpPromptById(data.item.client_native_id)) }
                        if(a_la_carte_selected){ setALaCarteItem(undefined) } else { setALaCarteItem(data.item) }
                    } else {
                        dispatch(player_getHelpPromptById(data.item.client_native_id))
                    }
                }}>
                    {getIconByFeature(data.item)}
                    <View style={{ flex:1, marginLeft:10 }}>
                        <View style={{ flex:1, flexDirection:'row', padding:3 }}>
                            <Text style={{ flex:1 }} size={16} color={Colors.utility.success} weight='bold'>{data.item.name.toUpperCase()}</Text>
                            {data.item.a_la_carte ?
                            <View>
                                {player_premium_item ?
                                    <Text size={14} color={Colors.incentive.gold} weight='bold'>Expires {moment(player_premium_item.expire_datetime).fromNow()}</Text>
                                :
                                    <Text size={14} color={Colors.incentive.gold} weight='bold'>A La Carte</Text>
                                }
                            </View>
                            :<></>}
                        </View>
                        <Text style={{ padding:3 }} size={14} color={a_la_carte_selected ? Colors.brand.midnight : Colors.shades.shade100} weight='semibold'>{data.item.description}</Text>
                    </View>
                </TouchableOpacity>
               
                {a_la_carte_item && a_la_carte_selected ?
                <View style={{ padding:10, backgroundColor:Colors.shades.shade100, borderRadius:8, marginTop:10 }}>
                    <Text size={14} color={Colors.incentive.gold} weight='bold' textAlign='right'>A La Carte Options</Text>
                    {player_premium_item ?
                    <View>
                        <Text size={15}>You have this item already!</Text>
                    </View>
                    : selected_price ?
                    <View>
                        <View style={{ flexDirection:'row', marginTop:5 }}>
                            <TouchableOpacity style={{ flex:1, borderRadius:8, padding:10, backgroundColor:Colors.utility.error, marginRight:2 }} onPress={() => setSelectedPrice(undefined)}>
                                <Text size={12} color={Colors.shades.white} textAlign='center' weight='semibold'>Cancel</Text>
                            </TouchableOpacity>
                            <TouchableOpacity disabled={a_la_carte_disclaimer?false:true} style={{ flex:2, opacity:a_la_carte_disclaimer?1:0.5, borderRadius:8, padding:10, backgroundColor:Colors.utility.success, marginLeft:2, marginRight:2 }} onPress={() => setSelectedPrice('day')}>
                                <Text size={12} color={Colors.shades.white} textAlign='center' weight='semibold'>Confirm ${selected_price == 'day' ? a_la_carte_item.daily_price:a_la_carte_item.monthly_price}</Text>
                            </TouchableOpacity>
                        </View>
                        <TouchableOpacity style={{ flexDirection:'row', marginTop:10 }} onPress={() => setALaCarteDiscliamer(!a_la_carte_disclaimer)}>
                            <View style={{ marginRight:5, height:20, width:20, borderRadius:4, borderWidth:2, borderColor:Colors.brand.midnight, justifyContent:'center', alignItems:'center' }}>
                                {a_la_carte_disclaimer ?
                                <View style={{ height:10, width:10, backgroundColor:Colors.brand.midnight }} />
                                :<></>}
                            </View>
                            <Text size={14} color={Colors.brand.midnight} weight='semibold'>I understand this feature will be unlocked for the next {selected_price==='day'?'24 hours':'month'} and ${selected_price == 'day' ? a_la_carte_item.daily_price : a_la_carte_item.monthly_price} will be deducted from my wallet immediately.</Text>
                        </TouchableOpacity>
                    </View>
                    :
                    <View style={{ flexDirection:'row', marginTop:5 }}>
                        <TouchableOpacity style={{ flex:1, borderRadius:8, padding:10, backgroundColor:Colors.brand.cyan, marginRight:2 }} onPress={() => dispatch(player_getHelpPromptById(data.item.client_native_id))}>
                            <Text size={12} color={Colors.shades.white} textAlign='center' weight='semibold'>Info</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flex:2, borderRadius:8, padding:10, backgroundColor:Colors.brand.midnight, marginLeft:2, marginRight:2 }} onPress={() => setSelectedPrice('day')}>
                            <Text size={12} color={Colors.shades.white} textAlign='center' weight='semibold'>${data.item.daily_price} - 24 Hours</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flex:2, borderRadius:8, padding:10, backgroundColor:Colors.brand.midnight, marginLeft:2 }} onPress={() => setSelectedPrice('month')}>
                            <Text size={12} color={Colors.shades.white} textAlign='center' weight='semibold'>${data.item.monthly_price} - 1 Month</Text>
                        </TouchableOpacity>
                    </View>
                    }
                </View>
                :<></>}
            </View>
        )
    }

    const isCVVValid = (account?:AccountProps, cvv?:string) => {
        if(!account){ return false }
        if(account.account_type != 'card'){ return true }
        if(!cvv){ return false }
        if(account.card_detail?.card_brand.toLowerCase() == 'amex'){
            if(cvv.length != 4){ return false }
            else { return true }
        }
        if(cvv.length != 3){ return false }
        return true
    }

    const handleNavFeatures = (dir:'next'|'back') => {
        let full_length = Math.ceil(premium_features.length / 4);
        if(dir == 'next'){
            let next_fts = [ ...premium_features.slice((feature_offset+1) * 4, ((feature_offset+1) * 4) + 4) ]
            if(next_fts.length == 0){ return setFeatureOffset(0) }
            return setFeatureOffset(feature_offset + 1)
        } else {
            if(feature_offset == 0){ return setFeatureOffset(full_length - 1) }
            return setFeatureOffset(feature_offset - 1)
        }
    }

    const handleCVVChange = (cvv:string) => {
        if(!change_plan){ return }
        setChangePlan({ ...change_plan, cvv })
    }
    if(loading){
        return (
            <View style={{ flex:1, backgroundColor:Colors.shades.white }}>
                <ActivityIndicator style={{ padding:30, alignSelf:'center' }} size='large' color={Colors.brand.midnight} />
            </View>
        )
    }

    let allow_buy = false
    if(premium_item && agreed_to_terms){
        allow_buy = true
        if(premium_item.identifier == 'week_premium_free' && !free_trial_agree){ allow_buy = false }
    }

    let my_ro_changed = my_recurring_order?.account_id != change_plan?.account_id ? true : my_recurring_order?.item_id != change_plan?.item_id ? true : false
    let updated_allowed = my_ro_changed && isCVVValid(selected_account, change_plan?.cvv) ? true : false

    return (
        <View style={{ flex:1, height: appHeight }}>
            <ImageBackground style={{ paddingTop:insets.top, height:app_size.height, width:app_size.width }}
            source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1699900855/premium_background_hwecgg.webp' }}
            
            resizeMode='cover'
            >
                <ScrollView style={{ flex:1 }}>
                    <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:20 }}
                    onPress={() => {
                        if(navigation.canGoBack()){ return navigation.goBack() }
                        navigation.navigate('Auth', { screen: 'EventStack' })
                    }}
                    >
                        <Icon name='chevron-left' type='feather' color={Colors.shades.white} size={20} />
                        <Text style={{ marginLeft:5 }} size={16} color={Colors.shades.white}>Back</Text>
                    </TouchableOpacity>
                    <View nativeID='premium_header' style={{ padding:15, marginTop:5 }}>
                        <Text size={28} weight='semibold' color={Colors.shades.shade100}>BettorEdge features only for Premium player</Text>
                        <Text style={{ marginTop:10 }} size={14} weight='regular' color={Colors.shades.shade100}>Select feature to see additional details</Text>
                    </View>
                    <View nativeID='feature_list' style={{ padding:20 }}>
                        <FlatList
                            data={features}
                            renderItem={renderFeatures}
                            //extraData={feature_offset}
                            //keyExtractor={(item) => `${feature_offset}:${item.name}`}
                        />
                    </View>
                </ScrollView>
                <View nativeID='scroll_indicator' style={{ flexDirection:'row', alignItems:'center', padding:10 }}>
                    <TouchableOpacity style={{ padding:5 }} onPress={() => handleNavFeatures('back')}>
                        <Icon name='chevron-left' type='feather' color={Colors.shades.white}/>
                    </TouchableOpacity>
                    <View style={{ flex:1, flexDirection:'row', alignItems:'center', justifyContent:'center' }}>
                        {bullets.map((b, i) => {
                            const active = feature_offset == i ? true : false
                            return (
                                <TouchableOpacity 
                                    style={{ height:10, width:40, marginRight:20, MarginLeft:20, borderRadius:100, backgroundColor:Colors.shades.shade100, opacity:active?1:0.5 }}
                                    onPress={() => setFeatureOffset(i)}
                                />
                            )
                        })}
                    </View>
                    <TouchableOpacity style={{ padding:5 }} onPress={() => handleNavFeatures('next')}>
                        <Icon name='chevron-right' type='feather' color={Colors.shades.white}/>
                    </TouchableOpacity>
                </View>
                <View nativeID='action_footer'>
                    {premium_item && player?.type != 'premium' ?
                    <View style={{  padding:10, paddingBottom:insets.bottom + 10, backgroundColor:Colors.shades.black_faded }}>
                        <View>
                            <TouchableOpacity 
                                disabled={!allow_buy}
                                style={{ borderRadius:22, padding:15, backgroundColor:Colors.utility.success, opacity:!allow_buy?0.5:1 }}
                                onPress={() => handleBuy(premium_item)}
                                >
                                <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>{premium_item.name} (${premium_item.price})</Text>
                            </TouchableOpacity>
                            {premium_item.identifier == 'week_premium_free' ?
                            <View nativeID='trial' style={{ flexDirection:'row', alignItems:'center', marginTop:15 }}>
                                <TouchableOpacity 
                                    style={{ padding:10, height:20, width:20, borderRadius:2, borderWidth:2, borderColor:Colors.shades.white, justifyContent:'center', alignItems:'center' }}
                                    onPress={() => setFreeTrialAgree(!free_trial_agree)}
                                    >
                                    {free_trial_agree ?
                                    <View style={{ height:15, width:15, backgroundColor:Colors.shades.white }} />
                                    :<></>}
                                </TouchableOpacity>
                                <Text style={{ marginLeft:10 }} size={14} color={Colors.shades.shade100} weight='semibold'>I understand that I will be charged $19.99 / month after my trial expires unless I cancel.</Text>
                            </View>
                            :<></>}
                            <View nativeID='terms' style={{ flexDirection:'row', alignItems:'center', marginTop:15 }}>
                                <TouchableOpacity 
                                    style={{ padding:10, height:20, width:20, borderRadius:2, borderWidth:2, borderColor:Colors.shades.white, justifyContent:'center', alignItems:'center' }}
                                    onPress={() => setAgreedToTerms(!agreed_to_terms)}
                                    >
                                    {agreed_to_terms ?
                                    <View style={{ height:15, width:15, backgroundColor:Colors.shades.white }} />
                                    :<></>}
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => window.open('https://bettoredge.com/bettoredge-premium-policy', '_blank')}>
                                    <Text style={{ marginLeft:10 }} size={14} color={Colors.shades.shade100} weight='semibold'>I agree to the terms and conditions outlined <Text weight='bold' size={14} color={Colors.utility.warning}>HERE</Text></Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                    : !player ?
                    <View style={{ padding:15, backgroundColor:Colors.incentive.gold, paddingBottom:insets.bottom + 10  }}>
                        <TouchableOpacity style={{ padding:15, borderRadius:22, backgroundColor:Colors.utility.success }} onPress={() => navigation.navigate('Authenticate')}>
                            <Text size={14} color={Colors.shades.white} textAlign='center' weight='bold'>GET STARTED</Text>
                        </TouchableOpacity>
                    </View>
                    :
                    <View style={{ padding:15, backgroundColor:Colors.incentive.gold, paddingBottom:insets.bottom + 10  }}>
                        <Text size={16} color={Colors.shades.white} textAlign='center' weight='bold'>Thank you for being a premium member!</Text>
                        {my_recurring_order ?
                        <View>
                            {['expiring'].includes(my_recurring_order.status) ?
                            <View style={{ padding:10 }}>
                                <TouchableOpacity 
                                    style={{ borderRadius:22, padding:15, backgroundColor:Colors.utility.success }}
                                    onPress={() => setChangePlan(my_recurring_order)}
                                >
                                    <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>Restore My Subscription</Text>
                                </TouchableOpacity>
                                <View style={{ marginTop:10 }}>
                                    <Text size={14} color={Colors.shades.white} textAlign='center'>Your premium subscription is set to expire at</Text>
                                    <Text style={{ marginTop:5 }} weight='bold' size={16} color={Colors.shades.white} textAlign='center'>{moment(my_recurring_order.next_order_datetime).format('MMM DD YYYY hh:mm a')}</Text>
                                </View>
                            </View>
                            :
                            <View nativeID='action_footer' style={{ padding:10 }}>
                                <TouchableOpacity
                                    style={{ borderRadius:22, padding:15, backgroundColor:Colors.brand.electric }}
                                    onPress={() => setChangePlan(my_recurring_order)}
                                    >
                                    <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>{my_recurring_order.item?.name} (Change Plan)</Text>
                                </TouchableOpacity>
                                <View style={{ marginTop:10 }}>
                                    <Text size={14} color={Colors.shades.white} textAlign='center'>You are paid up through:</Text>
                                    <Text style={{ marginTop:5 }} weight='bold' size={16} color={Colors.shades.white} textAlign='center'>{moment(my_recurring_order.next_order_datetime).format('MMM DD YYYY hh a')}</Text>
                                </View>
                            </View>
                            }
                        </View>
                        :<></>}
                    </View>
                }
                </View>


                {my_recurring_order && change_plan ?
                <View style={{ position:'absolute', top:0, bottom:0, left:0, right:0, padding:20, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.black_faded }}>
                    <View style={{ minWidth:app_size.width * 0.85, backgroundColor:Colors.shades.white, borderRadius:8 }}>
                        <View nativeID='change_plan_header' style={{ padding:20 }}>
                            <Text size={18} color={Colors.brand.midnight} weight='bold'>My Premium Subscription</Text>
                            <View style={{ marginTop:5, borderRadius:4, backgroundColor:Colors.highlights.highlight400Faded, padding:10 }}>
                                <Text style={{ marginBottom:10 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign='center'>Paid Through</Text>
                                <Text size={16} color={Colors.brand.midnight} weight='bold' textAlign='center'>{moment(my_recurring_order.next_order_datetime).format('MMM DD YYYY hh:mm a')}</Text>
                                <Text style={{ marginTop:10 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign='center'>Any modification will take effect after this date.</Text>
                            </View>
                        </View>
                        <View nativeID='current_account' style={{ padding:20 }}>
                            <Text size={16} color={Colors.brand.midnight} weight='semibold'>Account</Text>
                            <View style={{ marginTop:10, borderRadius:4, borderWidth:1, borderColor:Colors.shades.shade600, padding:10 }}>
                                <TouchableOpacity style={{ flexDirection:'row', alignItems:'center' }}
                                onPress={() => setSelectAccount(!select_account)}>
                                    <View style={{ flexDirection:'row', alignItems:'center', flex:1 }}>
                                        <Text size={14} color={Colors.brand.midnight} weight='semibold'>{change_plan.account?.account_label}</Text>
                                        {change_plan.account?.status != 'active' ?
                                        <Text size={12} color={Colors.utility.error} weight='regular'> - INACTIVE</Text>
                                        :<></>}
                                    </View>
                                    <Icon name='chevron-down' type='feather' color={Colors.brand.midnight} size={14} />
                                </TouchableOpacity>
                                {select_account ? 
                                <View style={{ marginTop:15, borderTopWidth:1, borderTopColor:Colors.shades.shade600 }}>
                                    <Text style={{ padding:5, backgroundColor:Colors.shades.shade100 }} color={Colors.utility.warning} weight='bold' textAlign='center'>Paypal and Instant Bank Transfer accounts are currently not eligible for subscription payments</Text>
                                    {accounts.map(a => {
                                        return (
                                            <TouchableOpacity 
                                                style={{ padding:10 }}
                                                onPress={() => {
                                                    setChangePlan({ ...change_plan, account_id: a.account_id, account: a, cvv:undefined })
                                                    setSelectAccount(false);
                                                }}>
                                                <Text size={14} color={Colors.brand.midnight} weight='semibold'>{a.account_label}</Text>
                                            </TouchableOpacity>
                                        )
                                    })}
                                    <TouchableOpacity 
                                        style={{ padding:10 }}
                                        onPress={() => {
                                            setSelectAccount(false);
                                            dispatch({ type: V1_UPDATE_WALLET_VISIBILE, visible:true })
                                        }}>
                                        <Text size={14} color={Colors.brand.electric} weight='semibold'>Add new account via my wallet</Text>
                                    </TouchableOpacity>
                                </View>
                                :<></>}
                            </View>
                            {my_ro_changed && change_plan.account?.account_type == 'card' ?
                                <View style={{ flexDirection:'row', alignItems:'center', marginTop:10, padding:10, backgroundColor:Colors.shades.shade600, borderRadius:4 }}>
                                    <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='bold'>Enter CVV on Card</Text>
                                    <TextInput
                                        style={{ padding:10, backgroundColor:Colors.shades.white, width:75, textAlign:'center', borderRadius:4, fontSize:14, color:Colors.brand.midnight }}
                                        onChangeText={(text) => handleCVVChange(text)}
                                        placeholder='1234'
                                        placeholderTextColor={Colors.shades.shade600}
                                    />
                                </View>
                            :<></>}
                        </View>
                        <View nativeID='current_plan' style={{ padding:20 }}>
                            <Text size={16} color={Colors.brand.midnight} weight='semibold'>Subscription</Text>
                            <View style={{ marginTop:10, borderRadius:4, borderWidth:1, borderColor:Colors.shades.shade600, padding:10 }}>
                                <TouchableOpacity style={{ flexDirection:'row', alignItems:'center' }}
                                onPress={() => setSelectPlan(!select_plan)}>
                                    <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='semibold'>{change_plan.item?.name}</Text>
                                    <Icon name='chevron-down' type='feather' color={Colors.brand.midnight} size={14} />
                                </TouchableOpacity>
                                {select_plan ? 
                                <View style={{ marginTop:15, borderTopWidth:1, borderTopColor:Colors.shades.shade600 }}>
                                    {premium_items.map(pi => {
                                        return (
                                            <TouchableOpacity 
                                                style={{ padding:10 }}
                                                onPress={() => {
                                                    setChangePlan({ ...change_plan, item_id: pi.item_id, item:pi })
                                                    //setPremiumItem(pi)
                                                    setSelectPlan(false);
                                                }}>
                                                <Text size={14} color={Colors.brand.midnight} weight='semibold'>${pi.price.toFixed(2)} {pi.name}</Text>
                                            </TouchableOpacity>
                                        )
                                    })}
                                </View>
                                :<></>}
                            </View>
                        </View>
                        <View style={{ padding:20, backgroundColor:Colors.shades.shade100 }}>
                            <View nativeID='action_row' style={{ flexDirection:'row' }}>
                                <TouchableOpacity 
                                    style={{ flex:1, padding:10, borderRadius:22, borderColor:Colors.brand.electric, borderWidth:1 }}
                                    onPress={() => setChangePlan(undefined)}
                                    >
                                    <Text size={14} color={Colors.brand.electric} weight='semibold' textAlign='center'>CLOSE</Text>
                                </TouchableOpacity>
                                {change_plan.status == 'expiring' ?
                                <TouchableOpacity 
                                    style={{ flex:2, marginLeft:5, padding:10, borderRadius:22, backgroundColor:Colors.utility.success }}
                                    onPress={() => handleRestoreSubscription()}
                                >
                                    <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>RESTORE</Text>
                                </TouchableOpacity>
                                :my_ro_changed ?
                                <TouchableOpacity 
                                    disabled={!updated_allowed}
                                    style={{ flex:2, marginLeft:5, padding:10, borderRadius:22, backgroundColor:Colors.utility.success, opacity:updated_allowed?1:0.5 }}
                                    onPress={() => handleUpdateSubscription()}
                                >
                                    <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>UPDATE SUBSCRIPTION</Text>
                                </TouchableOpacity>
                                :<></>}
                            </View>
                        </View>
                        {my_recurring_order?.status == 'active' ?
                        <View>
                            {!confirm_cancel ?
                            <TouchableOpacity style={{ padding:20, backgroundColor:'rgba(255, 245, 245, 1)', borderBottomRightRadius:8, borderBottomLeftRadius:8}}
                            onPress={async() => {
                                setConfirmCancel(true);
                            }}>
                                <Text size={14} color={Colors.highlights.highlight300} weight='bold' textAlign='center'>Cancel My Subscription</Text> 
                            </TouchableOpacity>
                            :
                            <View style={{ padding:20, backgroundColor:'rgba(255, 245, 245, 1)', borderBottomRightRadius:8, borderBottomLeftRadius:8 }}>
                                <Text size={16} color={Colors.highlights.highlight300} textAlign='center' weight='bold'>Are you sure?</Text>
                                <View style={{ flexDirection:'row', marginTop:10, marginBottom:10 }}>
                                    <TouchableOpacity 
                                        style={{ flex:2, padding:10, borderRadius:22, borderColor:Colors.brand.electric, borderWidth:1 }}
                                        onPress={() => {
                                            setConfirmCancel(false)
                                        }}
                                        >
                                        <Text size={14} color={Colors.brand.electric} weight='semibold' textAlign='center'>No - Stay Premium</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity 
                                        style={{ flex:1, marginLeft:5, padding:10, borderRadius:22, backgroundColor:Colors.utility.error }}
                                        onPress={() => {
                                            handleCancelSubscription()
                                        }}
                                        >
                                        <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>Yes - Cancel</Text>
                                    </TouchableOpacity>
                                </View>
                                <Text style={{ marginTop:5 }} size={14} color={Colors.highlights.highlight300} textAlign='center' weight='bold'>Your premium subscription will continue through {moment(my_recurring_order.next_order_datetime).format('MMM DD YYYY hh:mm a')}</Text>
                            </View>
                            }
                        </View>
                        :<></>}
                    </View>
                </View>
                :<></>}
            </ImageBackground>
        </View>
    )

}

export default PremiumScreen