import axios from "axios"
import moment from "moment";
import getEnvVars from '../../env';
import { cached_requests } from "../../screensV1/Auth/helpers/security";
import { errHandler } from "../errHandler";
import { util_dictionizeArray    } from './utils';
import { BracketProps, EventProps, LoadPriceRequestType, MatchProps, TournamentProps } from "../types/sr_svc";
import { V1_CLEAR_SCHEDULE_RANGE, V1_LOAD_ACTIVE_EVENT_IDS, V1_LOAD_ACTIVE_TOURNAMENT_IDS, V1_LOAD_ATHLETES, V1_LOAD_BRACKETS, V1_LOAD_EVENTS, V1_LOAD_LATEST_PRICES, V1_LOAD_LEAGUES, V1_LOAD_LEAGUE_SCHEDULE, V1_LOAD_MATCHES, V1_LOAD_ORDER_PRICES, V1_LOAD_PRICES, V1_LOAD_TEAMS, V1_LOAD_TOURNAMENTS, V1_LOAD_TOURNAMENT_SCHEDULE, V1_UPDATE_ATHLETE, V1_UPDATE_BRACKET, V1_UPDATE_CACHE_REQUEST, V1_UPDATE_EVENT, V1_UPDATE_EXOTIC, V1_UPDATE_GOLF_COURSES, V1_UPDATE_GOLF_LEADERS, V1_UPDATE_GOLF_SCORECARDS, V1_UPDATE_GOLF_TOURNAMENT_STATISTICS, V1_UPDATE_MATCH, V1_UPDATE_MMA_DETAILS, V1_UPDATE_TEAM, V1_UPDATE_TOURNAMENT, V1_UPDATE_TOURNAMENT_LEADERS } from "./types";
import { market_getLatestTradesByEventIds } from "./markets";
const { SR_SVC_API } = getEnvVars()


export const event_getLeagues = () => {
    return async(dispatch:any, getState:any) => {
        try {
            let request_cached = getState().util.cache_requests.find(cr => cr.request_url === `${SR_SVC_API}/v1/leagues?status=active`)
            if(request_cached){ return }
            dispatch({ type: V1_UPDATE_CACHE_REQUEST, cache_request: { request_url: `${SR_SVC_API}/v1/leagues?status=active`, timestamp:moment() } })
            const resp = await axios.get(`${SR_SVC_API}/v1/leagues?status=active`)
            dispatch({ type: V1_LOAD_LEAGUES, leagues: resp.data.leagues })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getEventIdsByDate = (league_id:string, date:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/events/date/${date}?league_id=${league_id}`)
            const { events, range_dates } = resp.data
            if(events.length > 0){ dispatch(market_getLatestTradesByEventIds(events.map(e => e.event_id), 'team')) }
            dispatch({ type: V1_LOAD_LEAGUE_SCHEDULE, events:util_dictionizeArray(events, 'event_id'), range_dates})
        } catch (e) {
            console.log(e)
        }
    }
}


export const event_getBracketsByLeagueId = async(league_id:string):Promise<BracketProps[]> => {
    const resp = await axios.get(`${SR_SVC_API}/v1/brackets/league/${league_id}?status=scheduled`)
    return resp.data.brackets
}

export const event_getTournamentsIdsByDate = (league_id:string, date:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/tournaments/date/${date}?league_id=${league_id}`)
            const { tournaments, range_dates } = resp.data
            dispatch({ type: V1_LOAD_TOURNAMENT_SCHEDULE, tournaments:util_dictionizeArray(tournaments, 'tournament_id'), range_dates })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_clearScheduleRange = () => {
    return (dispatch:any) => {
        dispatch({ type: V1_CLEAR_SCHEDULE_RANGE })
    }
}


export const event_getActiveEventIds = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/events/active`)
            dispatch(event_getBulkEvents(resp.data.event_ids))
            dispatch({ type: V1_LOAD_ACTIVE_EVENT_IDS, event_ids: resp.data.event_ids })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getEventByEventId = (event_id:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/events/event/${event_id}`)
            dispatch({ type: V1_UPDATE_EVENT, events: { [resp.data.event.event_id]: resp.data.event } })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_activateEvent = (event_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SR_SVC_API}/v1/events/event/activate`, {
                event_id,
                load_event_prices:true,
                load_player_prices:true
            })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getBulkEvents = (event_ids:string[]) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.post(`${SR_SVC_API}/v1/events/bulk/get`, { attribute:'event_id', values: event_ids })
            dispatch({ type:V1_LOAD_EVENTS, events: util_dictionizeArray(resp.data.events, 'event_id') })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getTeamByTeamId = (team_id:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            //const team = getState().event.teams.find(t => t.team_id == team_id)
            //if(team){ return }
            const resp = await axios.get(`${SR_SVC_API}/v1/teams/team/${team_id}`)
            dispatch({ type: V1_UPDATE_TEAM, team: resp.data.team })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getTeamByIdNoStore = async(team_id:string) => {
    try {
        const resp = await axios.get(`${SR_SVC_API}/v1/teams/team/${team_id}`);
        return resp.data.team
    } catch (e) {
        return undefined
    }
}

export const event_bulkGetTeams = (team_ids:string[]) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.post(`${SR_SVC_API}/v1/teams/bulk/get`, { attribute: 'team_id', values: team_ids })
            dispatch({ type: V1_LOAD_TEAMS, teams: resp.data.teams })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_searchTeams = (search_value:string, league_ids:string[], offset:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SR_SVC_API}/v1/teams/search`, { search_value, offset, league_ids })
            dispatch({ type: V1_LOAD_TEAMS, teams: resp.data.teams, offset })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_searchAthletes = (search_value:string, league_ids:string[], offset:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SR_SVC_API}/v1/athletes/search`, { search_value, offset, league_ids })
            dispatch({ type: V1_LOAD_ATHLETES, athletes: resp.data.athletes, offset })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getAthleteByAthleteId = (athlete_id:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            //const athlete = getState().event.athletes.find(a => a.athlete_id == athlete_id)
            //if(athlete){ return  }
            const resp = await axios.get(`${SR_SVC_API}/v1/athletes/athlete/${athlete_id}`)
            dispatch({ type: V1_UPDATE_ATHLETE, athlete: resp.data.athlete })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getExoticById = (exotic_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/exotics/exotic/${exotic_id}`)
            dispatch({ type: V1_UPDATE_EXOTIC, exotic: resp.data.exotic })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getAthletesByTeamId = (team_id:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/athletes/team/${team_id}`)
            dispatch({ type: V1_LOAD_ATHLETES, athletes: resp.data.athletes })
        } catch (e) {
            console.log(e)
        }
    }
}


export const event_bulkGetAthletes = (athlete_ids:string[]) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.post(`${SR_SVC_API}/v1/athletes/bulk/get`, { attribute: 'athlete_id', values: athlete_ids })
            dispatch({ type: V1_LOAD_ATHLETES, athletes: resp.data.athletes })
        } catch (e) {
            console.log(e)
        }
    }
}


//Tournaments
export const event_getActiveTournamentIds = (league_id?:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/tournaments/active?league_id=${league_id}`)
            dispatch({ type: V1_LOAD_ACTIVE_TOURNAMENT_IDS, tournament_ids: resp.data.tournament_ids })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getBulkTournaments = (tournament_ids:string[]) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.post(`${SR_SVC_API}/v1/tournaments/bulk/get`, { attribute:'event_id', values: tournament_ids })
            dispatch({ type: V1_LOAD_TOURNAMENTS, tournaments:util_dictionizeArray(resp.data.tournaments, 'tournament_id') })
        } catch (e) {
            console.log(e)
        }
    }
}



export const event_getTournamentByTournamentId = (tournament_id:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            //const cached_tournament = getState().event.tournaments.find(t => t.tournament_id == tournament_id)
            //if(cached_tournament){ return }
            const resp = await axios.get(`${SR_SVC_API}/v1/tournaments/tournament/${tournament_id}`)
            dispatch({ type: V1_UPDATE_TOURNAMENT, tournaments: { [resp.data.tournament.tournament_id]:resp.data.tournament } })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getTournamentByTournamentIdNoStore = async(tournament_id:string):Promise<TournamentProps | undefined> => {
    try {
        const resp = await axios.get(`${SR_SVC_API}/v1/tournaments/tournament/${tournament_id}`);
        return resp.data.tournament
    } catch (e) {
        console.log(e)
        return undefined
    }
}

export const event_getFutureDetailsByTournamentId = (tournament_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/tournaments/tournament/${tournament_id}`)
            let teams:string[] = []
            let athletes: string[] = []
            if(resp.data.tournament.participant_type == 'team'){ teams = resp.data.tournament.participants }
            if(resp.data.tournament.participant_type == 'athlete'){ athletes = resp.data.tournament.participants }
            if(teams.length > 0){ dispatch(event_bulkGetTeams(teams)) }
            if(athletes.length > 0){ dispatch(event_bulkGetAthletes(athletes)) }
            dispatch({ type: V1_UPDATE_TOURNAMENT, tournaments: { [resp.data.tournament.tournament_id]:resp.data.tournament } })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getMMADetailsByTournamentId = (tournament_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/tournaments/mma/details/${tournament_id}`)
            let athletes:string[] = []
            resp.data.matches.filter((m:MatchProps)=> m.participant_type == 'athlete').map((m:MatchProps) => m.participants.map(p => athletes.push(p)))
            if(athletes.length > 0){ dispatch(event_bulkGetAthletes(athletes)) }
            dispatch({ type:V1_UPDATE_MMA_DETAILS, mma_details: [resp.data.mma_details] })
            dispatch({ type: V1_LOAD_MATCHES, matches: util_dictionizeArray(resp.data.matches, 'match_id') })
        } catch (e) {
            
        }
    }
}

export const event_getGolfDetailsByTournamentId = (tournament_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/tournaments/golf/details/${tournament_id}`)
            dispatch({ type:V1_UPDATE_GOLF_LEADERS, golf_leaders:resp.data.golf_leaders })
            dispatch({ type: V1_UPDATE_GOLF_COURSES, golf_courses: [resp.data.golf_course] })
            dispatch({ type:V1_LOAD_ATHLETES, athletes: resp.data.athletes })
        } catch (e) {
            console.log(e)
        }
    }
}


export const event_getGolfTournamentStatistics = (tournament_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/tournaments/golf/statistics/${tournament_id}`)
            dispatch({ type:V1_UPDATE_GOLF_TOURNAMENT_STATISTICS, golf_tournament_statistics: resp.data.golf_tournament_statistics })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getScorecardByAthlete = (tournament_id:string, athlete_id:string, round_number:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/tournaments/golf/scorecard/${tournament_id}?athlete_id=${athlete_id}&round_number=${round_number}`)
            dispatch({ type:V1_UPDATE_GOLF_SCORECARDS, golf_scorecards:resp.data.golf_scorecards })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_updateTournament = (tournament:TournamentProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SR_SVC_API}/v1/tournaments/tournament/update`, { tournament })
            dispatch({ type: V1_UPDATE_TOURNAMENT, tournaments: { [resp.data.tournament.tournament_id]:resp.data.tournament } })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_updateMatch = (match:MatchProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SR_SVC_API}/v1/matches/match/update`, { match })
            return dispatch({ type: V1_UPDATE_MATCH, matches: { [resp.data.match.match_id] : resp.data.match } })

        } catch (e) {
            errHandler(e)
        }
    }
}

export const event_getAthletesByLeagueId = (league_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/athletes/league/${league_id}`)
            dispatch({ type: V1_LOAD_ATHLETES, athletes: resp.data.athletes })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getTournamentsByLeagueId = (league_id:string, status?:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/tournaments/league/${league_id}?status=${status}`)
            dispatch({ type: V1_LOAD_TOURNAMENTS, tournaments: util_dictionizeArray(resp.data.tournaments, 'tournament_id') })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getMatchByMatchId = (match_id:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            //const cached_match = getState().event.matches.find(m => m.match_id == match_id)
            //if(cached_match){ return }
            const resp = await axios.get(`${SR_SVC_API}/v1/matches/match/${match_id}`)
            return dispatch({ type: V1_UPDATE_MATCH, matches: { [resp.data.match.match_id] : resp.data.match } })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getMatchesByTournamentId = (tournament_id:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/matches/tournament/${tournament_id}`)
            let athletes:string[] = []
            let teams: string[] = []
            resp.data.matches.filter((m:MatchProps)=> m.participant_type == 'athlete').map((m:MatchProps) => m.participants.map(p => athletes.push(p)))
            resp.data.matches.filter((m:MatchProps)=> m.participant_type == 'team').map((m:MatchProps) => m.participants.map(p => teams.push(p)))
            if(athletes.length > 0){ dispatch(event_bulkGetAthletes(athletes)) }
            if(teams.length > 0){ dispatch(event_bulkGetTeams(teams)) }
            dispatch({ type: V1_LOAD_MATCHES, matches: util_dictionizeArray(resp.data.matches, 'match_id') })
        } catch (e) {
            console.log(e)
        }
    }
}


//External Prices
export const event_getLatestPrices = (event_id:string, event_type:string, market_id?:string, side?:string, participant_id?:string, external_name?:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/prices/latest/${event_id}/${event_type}?market_id=${market_id}&side=${side}&participant_id=${participant_id}&external_name=${external_name}`)
            dispatch({ type: V1_LOAD_LATEST_PRICES, prices:resp.data.prices })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getOrderPrices = (event_id:string, event_type:string, market_id?:string, side?:string, participant_id?:string, external_name?:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/prices/latest/${event_id}/${event_type}?market_id=${market_id}&side=${side}&participant_id=${participant_id}&external_name=${external_name}`)
            dispatch({ type: V1_LOAD_ORDER_PRICES, external_prices:resp.data.prices })
        } catch (e) {
            console.log(e)
        }
    }
}


export const event_getAllPrices = (event_id:string, event_type:string, offset:number, market_id:string, participant_id?:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/prices/all/${event_id}/${event_type}?offset=${offset}&market_id=${market_id}&participant_id=${participant_id}`)
            dispatch({ type: V1_LOAD_PRICES, prices:resp.data.prices })
        } catch (e) {
            console.log(e)
        }
    }
}

//BRACKETS
export const event_loadBracketsByLeagueId = (league_id:string, status?:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/brackets/league/${league_id}?status=${status}`)
            dispatch({ type:V1_LOAD_BRACKETS, brackets: resp.data.brackets })
        } catch (e) {
            console.log(e)
        }
    }
}

export const event_getBracketById = (bracket_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SR_SVC_API}/v1/brackets/bracket/${bracket_id}`)
            const { bracket, bracket_rounds, round_events, bracket_groups } = resp.data
            dispatch({ type: V1_UPDATE_BRACKET, bracket, bracket_rounds, bracket_groups, round_events })
        } catch (e) {
            console.log(e)
        }
    }
}


export const event_loadExternalPrices = (price_request:LoadPriceRequestType) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SR_SVC_API}/v1/prices/event/load`, { price_request })
        } catch (e) {
            console.log(e)
        }
    }
}