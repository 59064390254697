import React from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Colors from '../constants/Colorsv2';
import { BEEventApi, Share } from 'be-components';
import { ShareStateProps } from '../store/types/analytics_svc';
import { util_updateShare } from '../store/actionsv1/utils';
import { PublicPlayerProps } from '../store/types/auth_svc';
import { BEEventProps } from '../api/analytics/types';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type ShareModalHolderProps = {

}
const ShareModalHolder = ({}:ShareModalHolderProps) => {

    const dispatch = useDispatch();
    const app_size:{ width:number, height:number } = useSelector((state:any) => state.util.app_size, (left, right) => left.width == right.width);
    const insets = useSafeAreaInsets();
    const share_state:ShareStateProps = useSelector((state:any) => state.util.share, (left, right) => left.share_key == right.share_key);
    const player:PublicPlayerProps | undefined = useSelector((state:any) => state.player.player, (left, right) => left?.player_id == right?.player_id);
    if(!share_state.visible){ return <></> }
    return (
        <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, paddingBottom:insets.bottom, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end' }}>
            <Share
                maxHeight={app_size.height - insets.bottom - insets.top}
                title={share_state.title}
                body={share_state.body}
                generator='player'
                allow_edit={player?.role == 'admin' ? true : false}
                player_id={player?.player_id}
                onShare={(be_event:BEEventProps) => {
                    BEEventApi.saveEvent(be_event)
                    dispatch(util_updateShare({ ...share_state, visible:false, share_key:'0' }))
                }}
                link_type={share_state.link_type}
                link_sub_type={share_state.link_sub_type}
                type_id={share_state.type_id}
                onCancel={() => dispatch(util_updateShare({ ...share_state, visible:false, share_key:'0' }))}
                maxWidth={app_size.width}
                redirect_url={share_state.redirect_url}
                embed={share_state.embed}
            />
        </View>
    )
}

export default ShareModalHolder