import { V1_LOAD_APP_ALERTS, V1_UPDATE_APP_ALERT, V1_UPDATE_APP_SIZE, V1_UPDATE_AUDIO_HEIGHT, V1_UPDATE_HEADER_HEIGHT, V1_UPDATE_SHARE, V1_UPDATE_SOCKET_CONNECTED } from "./types"
import axios from 'axios';
import getEnvVars from '../../env';
import { AppAlertProps } from "../types/auth_svc";
import { ShareStateProps } from "../types/analytics_svc";

const { AUTH_SVC_API } = getEnvVars()


export const util_updateAppSize = (app_size:{ width:number, height:number }) => {
    return (dispatch:any) => {
        dispatch({ type: V1_UPDATE_APP_SIZE, app_size })
    }
}


export const util_updateAudioHeight = (audio_height:number) => {
    return (dispatch:any) => {
        dispatch({ type: V1_UPDATE_AUDIO_HEIGHT, audio_height })
    }
}

export const util_updateHeaderHeight = (header_height:number) => {
    return (dispatch:any) => {
        dispatch({ type: V1_UPDATE_HEADER_HEIGHT, header_height })
    }
}

export const util_updateRouteName = (route:string) => {
    return(dispatch:any) => {
        //UPDATE ROUTE NAME!!!
    }
}



export const util_getAppAlerts = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/alerts/active`)
            dispatch({ type: V1_LOAD_APP_ALERTS, app_alerts:resp.data.app_alerts })
        } catch (e) {
            console.log(e)
        }
    }
}

export const util_updateAppAlert = (app_alert:AppAlertProps) => {
    return (dispatch:any) => {
        //const resp = await axios
        dispatch({ type: V1_UPDATE_APP_ALERT, app_alert })

    }
}


export const util_socketChange = (connected:boolean) => {
    return(dispatch:any) => {
        dispatch({ type: V1_UPDATE_SOCKET_CONNECTED, connected })
    }
}




export const util_dictionizeArray = (objects_array:any[], primary_key:string) => {
    return objects_array.reduce((acc:{ [key: string] : any }, obj) => {
        acc[obj[primary_key]] = obj;
        return acc;
    }, {});
}





export const util_updateShare = (share:ShareStateProps) => {
    return(dispatch:any) => {
        dispatch({ type: V1_UPDATE_SHARE, share })
    }
}