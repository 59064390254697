import { SocialComponents } from 'be-components';
import React from 'react';
import { View } from "react-native"
import { useDispatch, useSelector } from 'react-redux';
import { social_updatePlayingPodcastEpisode } from '../../../store/actionsv1/social';


const PlaybackBar = ({}) => {
    const dispatch = useDispatch();
    const bottom_tab_height = useSelector((state:any) => state.util.bottom_tab_height, (left, right) => left == right);
    const podcast_episode = useSelector((state:any) => state.social.playing_podcast_episode, (left, right) => left?.podcast_episode_id == right?.podcast_episode_id);
    const player = useSelector((state:any) => state.player.player, (left, right) => left?.player_id == right?.player_id);
    if(!podcast_episode){ return <></> }
    return (
        <View style={{ position:'absolute', bottom:bottom_tab_height, left:0, right:0 }}>
            <SocialComponents.AudioPlayer
                player_id={player?.player_id}
                podcast_episode_id={podcast_episode.podcast_episode_id}
                onClose={() => dispatch(social_updatePlayingPodcastEpisode(undefined))}
            />
        </View>
    )
}

export default PlaybackBar