import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { auth_getNotificationByIdNOSTORE } from '../store/actionsv1/auth';
import { navigate } from '../navigation/RootNavigation';
import { V1_LOAD_NOTIFICATION_ID } from '../store/actionsv1/types';


const PushNotificationHandler = ({}) => {

    const dispatch = useDispatch();
    const notification_id = useSelector((state:any) => state.auth.notification_id, (left, right) => left == right);

    useEffect(() => {
        if(!notification_id){ return }
        handleNotification(notification_id)
    },[notification_id])

    const handleNotification = async(notification_id:string) => {
        console.log('loading notification')
        const pn = await auth_getNotificationByIdNOSTORE(notification_id);
        if(!pn){ console.log('couldnt find notification'); return } //Error retreiving the noticication
        if(!pn.options?.data){ console.log('There was no data for that notification'); return }
        navigate(pn.options.data.pageStack, { screen: pn.options.data.page, initial: false, params: pn.options.data.pageParams });
        dispatch({ type: V1_LOAD_NOTIFICATION_ID, notification_id: undefined });
    }

    return <></>
}

export default PushNotificationHandler