import moment from "moment"
import { MarketReducerProps, V1_LOAD_BEST_AVAILABLE, V1_LOAD_DRAFT_ORDER, V1_LOAD_EXTERNAL_PRICE_COMPARISON, V1_LOAD_FILTERED_ORDERS, V1_LOAD_LATEST_TRADES, V1_LOAD_MARKETS, V1_LOAD_ORDERS, V1_LOAD_ORDER_BOOK, V1_LOAD_ORDER_STATS, V1_LOAD_RESOLVED_ORDERS, V1_LOAD_SUGGESTED_MARKETS, V1_LOAD_TRADES, V1_UPDATE_CHALLENGER, V1_UPDATE_LATEST_TRADE, V1_UPDATE_LATEST_TRADES, V1_UPDATE_MARKET_RESOLVE, V1_UPDATE_ORDER, V1_UPDATE_ORDERS, V1_UPDATE_ORDER_SUGGESTIONS, V1_UPDATE_PLAYER_FILTERS, V1_UPDATE_TRADE } from "../actionsv1/types"
import { EventOrderStatProps, OrderProps, OrderStatsProps, PlayerFilterProps, TradeProps } from "../types/mk_svc"
import { markets_updateOrderBookWithOrder } from "./helpers"


const DEFAULT_STATE:MarketReducerProps = {
    markets: [],
    trades: [],
    orders: [],
    orders_lists: [],
    order_book: undefined,
    order_stats:{},
    best_available:{},
    latest_trades: {},
    external_price_comparison:{},
    market_resolve: undefined,
    latest_trades_lists:[],
    draft_order: undefined,
    selling_position:undefined,
    challenger:undefined,
    player_filters:[],
    filtered_orders:[],
    suggested_markets_list: {
        updated: 0,
        suggested_markets: []
    }
}

const eventReducer = (state = DEFAULT_STATE, action:any):MarketReducerProps => {
    switch(action.type){
        case V1_LOAD_MARKETS: 
            return {
                ...state,
                markets: action.markets
            }
        case V1_LOAD_ORDERS:
            return {
                ...state,
                orders: action.offset === 0 ? action.orders : state.orders.filter(o => !action.orders.find((no:OrderProps) => no.order_id == o.order_id)).concat(action.orders)
            }
        case V1_LOAD_FILTERED_ORDERS:
            return {
                ...state,
                filtered_orders: action.order_ids
            }
        case V1_LOAD_EXTERNAL_PRICE_COMPARISON:
            return {
                ...state,
                external_price_comparison: action.external_price_comparison
            }
        case V1_UPDATE_CHALLENGER:
            return {
                ...state,
                challenger: action.player
            }
        case V1_UPDATE_PLAYER_FILTERS:
            return {
                ...state,
                player_filters: state.player_filters.filter(pf => !action.player_filters.find((npf:PlayerFilterProps) => npf.player_filter_id == pf.player_filter_id)).concat(action.player_filters)
            }
        case V1_LOAD_BEST_AVAILABLE:
            let init_avail = state.best_available
            if(action.initial){
                init_avail = {}
            }
            return {
                ...state,
                best_available: { 
                    ...init_avail,
                    ...action.best_available 
                },
            }
        case V1_LOAD_LATEST_TRADES:
            return {
                ...state,
                latest_trades: {
                    ...state.latest_trades,
                    ...action.latest_trades
                }
            }
        case V1_LOAD_ORDER_BOOK:
            return {
                ...state,
                order_book: { ...action.order_book, updated: moment().format('hh:mm ss') }
            }
        case V1_UPDATE_ORDERS:
            let new_orders = [ ...state.orders ]
            let new_ob = state.order_book
            action.orders.map((o:OrderProps) => {
                if(o.status != 'approved'){
                    new_orders = new_orders.filter(no => no.order_id != o.order_id)
                } else {
                    new_orders = new_orders.filter(no => no.order_id != o.order_id).concat(o)
                }
                new_ob = markets_updateOrderBookWithOrder(new_ob, state.orders, o)
            })
            return {
                ...state,
                orders: new_orders,
                order_book: new_ob
            }

        case V1_UPDATE_ORDER:

            if(action.order.status !== 'approved'){
                return {
                    ...state,
                    orders: state.orders.filter(o => o.order_id != action.order.order_id),
                    order_book: markets_updateOrderBookWithOrder(state.order_book, state.orders, action.order)
                }
            }
            return {
                ...state,
                orders: state.orders.filter(o => o.order_id != action.order.order_id).concat(action.order),
                order_book: markets_updateOrderBookWithOrder(state.order_book, state.orders, action.order)
            }
        case V1_LOAD_ORDER_STATS:
            let init_stat = state.order_stats
            if(action.initial){
                init_stat = {}
            }
            return {
                ...state,
                order_stats: {
                    ...init_stat,
                    ...action.order_stats
                }
            }
        case V1_UPDATE_LATEST_TRADE:
            let latest_trades = state.latest_trades[`${action.latest_trades[0].event_type}:${action.latest_trades[0].event_id}`]
            if(!latest_trades || latest_trades.length == 0){
                return {
                    ...state,
                    latest_trades: {
                        ...state.latest_trades,
                        [`${action.latest_trades[0].event_type}:${action.latest_trades[0].event_id}`]: [action.latest_trades]
                    }
                }
            }
            //There will be two sides of the trade.  Therefore, we need to remove both of them and replace with the new ones
            action.latest_trades.map((trade:TradeProps) => {
                let matching_trade = latest_trades.find(t => t.market_id == trade.market_id && t.side_id == trade.side_id && t.market_type == trade.market_type);
                latest_trades = latest_trades.filter(t => t.trade_id != matching_trade?.trade_id).concat(trade)
            })
            return {
                ...state,
                latest_trades: {
                    ...state.latest_trades,
                    [`${action.latest_trades[0].event_type}:${action.latest_trades[0].event_id}`]: latest_trades
                }
            }
            //There are some latest trades
        case V1_LOAD_DRAFT_ORDER:
            return {
                ...state,
                draft_order: action.order,
                selling_position: action.position
            }
        case V1_LOAD_TRADES:
            return {
                ...state,
                trades: action.offset === 0 ? action.trades : state.trades.concat(action.trades)
            }
        case V1_UPDATE_TRADE:
            return {
                ...state,
                trades: state.trades.filter(t => t.trade_id != action.trade.trade_id).concat(action.trade)
            }
        case V1_LOAD_SUGGESTED_MARKETS:
            return {
                ...state,
                suggested_markets_list: {
                    updated: state.suggested_markets_list.updated + 1,
                    suggested_markets: action.suggested_markets
                }
            }
        case V1_UPDATE_MARKET_RESOLVE:
            return {
                ...state,
                market_resolve: action.market_resolve
            }
        default:
            return state
    }
 }
 
 export default eventReducer